import { BaseTextFieldProps, Typography } from "@mui/material";
import { ErrorMessage } from "../../../styles/common";
import { TextBox } from "./styles";

type Value = string | number;

export interface TextBoxProps {
  id?: string;
  isDisabled?: boolean;
  defaultValue?: Value;
  value?: Value;
  placeholder?: string;
  type?: string;
  width?: string;
  height?: string;
  name?: string;
  className?: string;
  errorMessage?: string | null;
  onChange?: (value: any) => void;
  onBlur?: (event: any) => void;
  materialProps?: BaseTextFieldProps;
}

const TextBoxComponent: React.FC<TextBoxProps> = ({
  id,
  width,
  height,
  className,
  defaultValue,
  type,
  placeholder,
  errorMessage,
  value,
  isDisabled,
  onChange,
  onBlur,
  name,
  materialProps,
}) => {
  return (
    <>
      <TextBox
        name={name}
        className={className}
        inputProps={{ style: { height } }}
        value={value}
        disabled={isDisabled}
        type={type || "text"}
        width={width}
        defaultValue={defaultValue}
        variant="outlined"
        size="medium"
        label={placeholder}
        id={id}
        onChange={(e) => onChange && onChange(e.target.value)}
        onBlur={onBlur}
        {...materialProps}
      />
      <ErrorMessage>
        <Typography fontSize="12px" paddingLeft="8px">
          {errorMessage}
        </Typography>
      </ErrorMessage>
    </>
  );
};

export default TextBoxComponent;
