import styled from "@emotion/styled";

import TableCell from "@mui/material/TableCell";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";

export const StyledTableColors = styled(TableCell)`
background-color: ${({ theme }) => theme.colours.grey.secondary};
`;
export const StyledCollapseTable = styled(Collapse)`
max-width:500px;
`;
export const StyledTableHeader = styled(TableCell)`
font-weight:bold;
`;
export const StyledAutocompleteContainer = styled(Box)`
  width:300px;
`;
export const SubmitButtonSection = styled(Box)`
  margin-left:20px;
  width:150px;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom:20px;
`;
export const StyledBoxFlex = styled(Box)`
display: flex;
`;