import styled from "@emotion/styled";
import { Box, Container, Table } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { FlexContainer } from "../../styles/common";

export const StyledTableCell = styled(TableCell)`
  background-color: ${({ theme }) => theme.colours.grey.secondary};
`;
export const FormLeft = styled(Box)``;
export const FormCheckbox = styled(Box)`
  display: flex;
`;

export const BoxQuestion= styled(Box)`
  display: flex;
  flex-direction: row-reverse;
`

export const Form = styled.form`
  padding: 10px;
  width: 60%;
  min-width: 500px;
  max-width: 700px;
`;
export const ModalQuestion= styled(Container)`
margin: 2rem 0;
`;
export const ModalForm = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    //width: 400px;
    background-color: #fff;
    border: 2px solid #000;
    boxShadow: 24;
    p: 4;
`;
export const DetailsContainer = styled.div`
margin: 2rem 0;

`;

export const TableModal = styled(Table)`
  margin: 2rem 0 2rem 0;
`;
export const FormLeftRow = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
export const ContainerSurvey = styled(Container)`
margin-bottom: 5rem;
`;
export const SurveyBox = styled(Box)`
  margin-bottom: 2rem;
`;
export const RowContainer = styled.div`
  width: 100%;
  align-items: end;
  padding-top: 2rem;
  justify-content: space-between;
`;
export const Label = styled.p`
  width: 100%;
  align-items: center;
  padding-right: 1rem;
  color: ${({ theme }) => theme.colours.grey.primary};
`;
export const FormContainer = styled(FlexContainer)`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//       backgroundColor: theme.palette.common.black,
//       color: theme.palette.common.white,
//     },
//     [`&.${tableCellClasses.body}`]: {
//       fontSize: 14,
//     },
//   }));
