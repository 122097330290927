import ProtectedRoute from "../../components/ProtectedRoute";
import SurveyComponent from "../../components/Survey";

function SurveyComponentPage() {
  return (
    <ProtectedRoute>
      <SurveyComponent />
    </ProtectedRoute>
  );
}

 export default SurveyComponentPage;
