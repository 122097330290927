import { QuestionType } from "../../types";

export interface Survey {
  id: string | undefined;
  dateCreated: string | undefined;
  description: string | undefined;
  canSubmitMultiple: boolean;
}

export type LanguagesOption = "en" | "pl"
export type DayOfWeek = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY"

export const SELECT_DAYS_IN_WEEK: Record<DayOfWeek, DayOfWeek> = {
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY"
};
export type  SurveyActivationEvent =
  "USER_LOAD_LESS_THAN_HALF"
  | "USER_LOAD_LESS_THAN_THIRD"
  | "USER_LOGGED_IN"
  | "USER_REQUESTED_LOGOUT"
  | "AFTER_FIRST_BREAK"
  | "AFTER_SECOND_BREAK"
  | "AFTER_PAUSE_FOR_A_MEETING"
  | "AFTER_6MINUTE_CALL"
  | "AFTER_43RD_CALL"
  | "AFTER_8TH_INTERVIEW_TODAY"
  | "AFTER_6TH_ISSUE_SOLVED";

export const SELECT_ACTIVATION_EVENT: Record<SurveyActivationEvent, SurveyActivationEvent> = {
  USER_LOAD_LESS_THAN_HALF: "USER_LOAD_LESS_THAN_HALF",
  USER_LOAD_LESS_THAN_THIRD: "USER_LOAD_LESS_THAN_THIRD",
  USER_LOGGED_IN: "USER_LOGGED_IN",
  USER_REQUESTED_LOGOUT: "USER_REQUESTED_LOGOUT",
  AFTER_FIRST_BREAK: "AFTER_FIRST_BREAK",
  AFTER_SECOND_BREAK: "AFTER_SECOND_BREAK",
  AFTER_PAUSE_FOR_A_MEETING: "AFTER_PAUSE_FOR_A_MEETING",
  AFTER_6MINUTE_CALL: "AFTER_6MINUTE_CALL",
  AFTER_43RD_CALL: "AFTER_43RD_CALL",
  AFTER_8TH_INTERVIEW_TODAY: "AFTER_8TH_INTERVIEW_TODAY",
  AFTER_6TH_ISSUE_SOLVED: "AFTER_6TH_ISSUE_SOLVED"
};

export const SurveyActivationEventTranslations = {
  "USER_LOAD_LESS_THAN_HALF": { "en": "User Load < 50%", "pl": "User Load < 50%" },
  "USER_LOAD_LESS_THAN_THIRD": { "en": "USER_LOAD_LESS_THAN_THIRD", "pl": "USER_LOAD_LESS_THAN_THIRD" },
  "USER_LOGGED_IN": { "en": "After Log-In", "pl": "Po logowaniu" },
  "USER_REQUESTED_LOGOUT": { "en": "Before log-out", "pl": "Przed wylogowaniem" },
  "AFTER_FIRST_BREAK": { "en": "After the first break", "pl": "Po pierwszym przerwie" },
  "AFTER_SECOND_BREAK": { "en": "After the second break", "pl": "Po drugiej przerwie" },
  "AFTER_PAUSE_FOR_A_MEETING": { "en": "After a consultancy / meeting", "pl": "Po doradztwo / spotkanie" },
  "AFTER_6MINUTE_CALL": {
    "en": "After the call which lasts at least 6 minutes",
    "pl": "Po pierwszej rozmowie która trwa co najmniej 6 minut"
  },
  "AFTER_43RD_CALL": { "en": "After the 43th call today", "pl": "Po 43 wezwaniu dzisiaj" },
  "AFTER_8TH_INTERVIEW_TODAY": { "en": "After 8th interview today", "pl": "Po 8 rozmowie kwalifikacyjnej dzisiaj" },
  "AFTER_6TH_ISSUE_SOLVED": {
    "en": "After solving 6th issue of the clients today",
    "pl": "Po rozwiązaniu 6 problemu klientów dzisiaj"
  }
};

export const QUESTION_TYPE: Record<QuestionType, QuestionType> = {
  FREE_TEXT: "FREE_TEXT",
  DECIMAL_GRADE: "DECIMAL_GRADE",
  SINGLE_CHOICE: "SINGLE_CHOICE"
};

export const LANGUAGES: Record<LanguagesOption, LanguagesOption> = {
  en: "en",
  pl: "pl"
};

export interface ISurveyData {
  id: string;
  description: string;
  dateCreated: string;
  button: HTMLButtonElement;
}

export interface SurveyDataActivation {
  activateFrom: string;
  activateFromHour: number;
  activateOnDays: string[];
  activateOnEvents: string[];
  activateTo: string;
  activateToHour: number;
}

export interface ISurveyDataResult {
  surveyId: string | undefined;
  dateCreated: string | undefined;
  description: string | undefined;
}
