import { useQuery } from "@apollo/client";
import keys from "lodash/keys";
import { useMemo, useState } from "react";
import map from "lodash/map";
import reduce from "lodash/reduce";

import { FILTER_VALUES_QUERY } from "../../../api/dashboard/queries";

import { Option } from "../../../types";
import { IFilterValues, IReportFilters } from "../../Home/types";


type IFiltersOptions = Record<keyof IFilterValues, Option[]>;

interface Result {
  onApplyQuery: () => void;
  onUpdateFilter: (filter: keyof IReportFilters, value: any) => void;
  filters: IReportFilters;
  applyFilters: IReportFilters;
  filtersOptions: IFiltersOptions;
}

const initReportFilters = (): IReportFilters => ({
  surveyFrom: "2023-01-01",
  surveyTo: "2023-12-31",
  channelIn: [],
  departmentIn: [],
  surveyIdIn: [],
  teamIn: [],
});

const initFilterValues = (): IFilterValues => ({
  channels: [],
  departments: [],
  surveys: [],
  teams: [],
});

const getFiltersOptions = (filterValues: IFilterValues): IFiltersOptions =>
  reduce(
    keys(filterValues),
    (result, key) => ({
      ...result,
      [key]: map(filterValues[key], (it) => ({ value: it, label: it })),
    }),
    {} as IFiltersOptions
  );

export const useResponses = (): Result => {
  const [filters, setFilters] = useState<IReportFilters>(initReportFilters());
  const [applyFilters, setApplyFilters] = useState<IReportFilters>(
    initReportFilters()
  );

  const { data: filterValuesData } = useQuery(FILTER_VALUES_QUERY, {
    fetchPolicy: "network-only",
  });

  const onUpdateFilter = (filterKey: keyof IReportFilters, value: any) => {
    setFilters((current) => ({ ...current, [filterKey]: value }));
  };

  const filtersOptions: IFiltersOptions = useMemo(
    () =>
      getFiltersOptions(
        filterValuesData?.reports?.filterValues || initFilterValues()
      ),
    [filterValuesData]
  );

  const onApplyQuery = () => {
    setApplyFilters(filters);
  };

  return {
    onApplyQuery,
    filters,
    applyFilters,
    onUpdateFilter,
    filtersOptions,
  };
};
