import styled from "@emotion/styled";

export const FlexContainer = styled.div`
  display: flex;
`;

export const FlexContainerCentered = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
`;

export const HorizontalSeparator = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`;

export const VerticalSeparator = styled.div<{ height: string }>`
  height: ${({ height }) => height};
`;

export const ErrorMessage = styled.div`
  padding-top: 3px;
  color: ${({ theme }) => theme.colours.red.primary};
  ${({ theme }) => theme.typography.smallText}
`;
