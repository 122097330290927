import { useLazyQuery, useQuery } from "@apollo/client";
import keys from "lodash/keys";
import { useEffect, useMemo, useState } from "react";
import map from "lodash/map";
import reduce from "lodash/reduce";

import {
  DASHBOARD_CHART_DATA_QUERY, DASHBOARD_SCORE_DATA_QUERY,
  DASHBOARD_TABLE_DATA_QUERY, DASHBOARD_VOLUME_DATA_QUERY,
  FILTER_VALUES_QUERY
} from "../../../api/dashboard/queries";

import { CHART_DIMENSION, GRANULARITY } from "../../../enums";

import { ChartDimension, DataSeries, Option } from "../../../types";
import {
  IFilterValues,
  IGranularity,
  IReportFilters,
  ITableData, ScoreData, VolumeData
} from "../types";

interface DataLoaders {
  table: boolean;
  chart: boolean;
}

type IFiltersOptions = Record<keyof IFilterValues, Option[]>;

interface Result {
  onApplyQuery: () => void;
  onUpdateGranularity: (granularity: IGranularity) => void;
  onUpdateGroupBy: (groupBy: ChartDimension) => void;
  onUpdateFilter: (filter: keyof IReportFilters, value: any) => void;
  filters: IReportFilters;
  granularity: IGranularity;
  groupBy: ChartDimension;
  loaders: DataLoaders;
  filtersOptions: IFiltersOptions;
  tableData: ITableData | undefined;
  chartData: DataSeries[] | undefined;
  volumeData: VolumeData[] | undefined;
  scoreData: ScoreData[] | undefined;
}

const initReportFilters = (): IReportFilters => ({
  surveyFrom: "2023-01-01",
  surveyTo: "2023-12-31",
  channelIn: [],
  departmentIn: [],
  surveyIdIn: [],
  teamIn: []
});

const initFilterValues = (): IFilterValues => ({
  channels: [],
  departments: [],
  surveys: [],
  teams: []
});

const getFiltersOptions = (filterValues: IFilterValues): IFiltersOptions =>
  reduce(
    keys(filterValues),
    (result, key) => ({
      ...result,
      [key]: map(filterValues[key], (it) => ({ value: it, label: it }))
    }),
    {} as IFiltersOptions
  );

export const useDashboardManager = (): Result => {
  const [filters, setFilters] = useState<IReportFilters>(initReportFilters());
  const [groupBy, setGroupBy] = useState<ChartDimension>(CHART_DIMENSION.TEAM);

  const [granularity, setGranularity] = useState<IGranularity>(GRANULARITY.DAY);

  const { data: filterValuesData } = useQuery(FILTER_VALUES_QUERY, {
    fetchPolicy: "network-only"
  });

  const [
    queryTableData,
    { data: tableQueryData, loading: isTableDataQueryLoading }
  ] = useLazyQuery(DASHBOARD_TABLE_DATA_QUERY, { fetchPolicy: "network-only" });

  const [
    queryChartData,
    { data: chartQueryData, loading: isChartDataQueryLoading }
  ] = useLazyQuery(DASHBOARD_CHART_DATA_QUERY, { fetchPolicy: "network-only" });

  const [
    queryVolumeData,
    { data: volumeQueryData }
  ] = useLazyQuery(DASHBOARD_VOLUME_DATA_QUERY, { fetchPolicy: "network-only" });

  const [
    queryScoreData,
    { data: scoreQueryData }
  ] = useLazyQuery(DASHBOARD_SCORE_DATA_QUERY, { fetchPolicy: "network-only" });

  const onUpdateGranularity = (granularity: IGranularity) => {
    setGranularity(granularity);
  };

  const onUpdateFilter = (filterKey: keyof IReportFilters, value: any) => {
    setFilters((current) => ({ ...current, [filterKey]: value }));
  };

  const onUpdateGroupBy = (groupBy: ChartDimension) => {
    setGroupBy(groupBy);
  };

  const filtersOptions: IFiltersOptions = useMemo(
    () =>
      getFiltersOptions(
        filterValuesData?.reports?.filterValues || initFilterValues()
      ),
    [filterValuesData]
  );

  const tableData: ITableData | undefined = tableQueryData?.reports?.table;
  const chartData: DataSeries[] | undefined = chartQueryData?.reports?.chart;
  const volumeData: VolumeData[] | undefined = volumeQueryData?.reports?.volume;
  const scoreData: ScoreData[] | undefined = scoreQueryData?.reports?.score;

  const fetchTableData = () =>
    queryTableData({
      variables: {
        filters,
        granularity
      }
    });

  const fetchChartData = () =>
    queryChartData({
      variables: {
        filters,
        groupBy,
        granularity
      }
    });

  const fetchVolumeData = () => queryVolumeData({
    variables: {
      filters,
      groupBy
    }
  });

  const fetchScoreData = () => queryScoreData({
    variables: {
      filters,
      groupBy
    }
  });

  const onApplyQuery = () => {
    fetchTableData();
    fetchChartData();
    fetchVolumeData();
    fetchScoreData();
  };

  useEffect(() => {
    fetchTableData();
  }, [filters, granularity]);

  useEffect(() => {
    fetchChartData();
  }, [filters, groupBy, granularity]);

  useEffect(() => {
    fetchVolumeData();
    fetchScoreData();
  }, [filters, groupBy]);

  const loaders: DataLoaders = {
    table: isTableDataQueryLoading,
    chart: isChartDataQueryLoading
  };

  return {
    chartData,
    tableData,
    volumeData,
    scoreData,
    filters,
    granularity,
    groupBy,
    loaders,
    onUpdateGranularity,
    onUpdateGroupBy,
    onUpdateFilter,
    onApplyQuery,
    filtersOptions
  };
};
