import React, { PropsWithChildren } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
  Theme
} from "@mui/material";

import { Option } from "../../../types";

type Props = PropsWithChildren<{
  id: string;
  multiple?: boolean
  sx?: SxProps<Theme> | undefined;
  placeholder: string;
  isDisabled?: boolean;
  value?: any;
  options: Option[];
  defaultValue?: any
  onChange?: (value: any) => void;
  materialProps?: SelectProps;
}>;

const SelectComponent: React.FC<Props> = ({
                                            id,
                                            sx,
                                            placeholder,
                                            isDisabled,
                                            value,
                                            options,
                                            defaultValue,
                                            onChange,
                                            materialProps,
                                            multiple
                                          }) => {
  if (multiple && !Array.isArray(value)) {
    console.log("multiple set, but value is not array! id:", id, ";placeholder: ", placeholder, "; value:", value);
  }

  return (
    <FormControl fullWidth sx={sx}>
      <InputLabel id={`${id}-label`}>{placeholder}</InputLabel>
      <Select
        id={id}
        multiple={multiple}
        labelId={id}
        disabled={isDisabled}
        value={value}
        defaultValue={defaultValue}
        label={placeholder}
        onChange={(e) => onChange && onChange(e.target.value)}
        {...materialProps}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;
