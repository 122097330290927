import ProtectedRoute from "../../components/ProtectedRoute";
import Responses from "../../components/Responses";

function ResponsesPage() {
  return (
    <ProtectedRoute>
      <Responses />
    </ProtectedRoute>
  );
}

export default ResponsesPage;
