import styled from "@emotion/styled";
import { FlexContainerCentered } from "../../styles/common";

import $LogoutIcon from "@mui/icons-material/LogoutRounded";
import $PersonRoundedIcon from "@mui/icons-material/PersonRounded";

import Link from "../ui-kit/Link";

export const Container = styled.header`
  position: fixed;
  box-sizing: border-box;

  background: radial-gradient(
    circle,
    ${({ theme }) => theme.colours.green.secondary} 4%,
    ${({ theme }) => theme.colours.green.primary} 100%
  );

  box-shadow: ${({ theme }) => theme.boxShadow.modals};
  color: white;
  display: grid;
  grid-template-columns: 150px auto auto;
  width: 100%;
  height: 50px;
  position: fixed;
  z-index: 9999;
  top: 0px;
  width: 100%;
`;

export const Center = styled.div``;

export const LeftSection = styled(FlexContainerCentered)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserIcon = styled($PersonRoundedIcon)`
  border-radius: 50%;
  padding: 3px;
  border: 1px solid white;
  width: 18px;
  height: 18px;
`;

export const LogoutIcon = styled($LogoutIcon)`
  border-radius: 50%;
  border: 1px solid transparent;
  transition: border-color 0.3s ease-out;
  padding: 4px;
  width: 18px;
  height: 18px;

  &:hover {
    border-color: white;
  }
`;

export const UserProfileLink = styled(Link)``;

export const UserProfileLinkInner = styled.div`
  display: inline-flex;
  padding: 4px 8px;
  align-items: center;
  font-size: 0.8rem;
  color: white;
`;

export const RightSection = styled(FlexContainerCentered)`
  justify-content: right;
  padding-right: 18px;
`;
