import React from "react";


import { useAuthContext } from "../../context/auth";


import PageHeader from "../PageHeader";


import { ButtonsContainer, Container, MarginButton } from "./styles";

import { CHAT_CLIENT_URL, STORAGE_KEYS } from "../../constants";
import storage from "../../utils/storage";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { GET_EVENTS_FOR_TEAM } from "../../api/teamProfile/queries";
import { SurveyActivationEvent, SurveyActivationEventTranslations } from "../Survey/types";
import { useNavigate } from "react-router-dom";
import { USER_ROLES } from "../../enums";


export const SEND_EVENT_TO_SERVER = gql`
query SendEventToServer($event: SurveyActivationEvent!) {
  chatClient {
    sendEvent(event: $event)
  }
}
`;

const ChatComponent: React.FC = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  // HR should not see chat page
  if (user?.role === USER_ROLES.HR) {
    navigate("/reports");
  }

  const token = storage.get(STORAGE_KEYS.AUTH_TOKEN);
  const iFrameId = "iFrChat";
  const [querySendEvent] = useLazyQuery(SEND_EVENT_TO_SERVER, {
    fetchPolicy: "network-only"
  });

  const { data: assignedEventsData } = useQuery(GET_EVENTS_FOR_TEAM, {
    fetchPolicy: "network-only",
    variables: {
      team: user?.team || ""
    }
  });

  const assignedEvents = assignedEventsData?.teams?.assignedEvents;

  const sendEvent = (eventToSend: SurveyActivationEvent) => {
    querySendEvent({
      variables: { event: eventToSend }
    });
  };

  return (
    <Container>
      <PageHeader>Lucy</PageHeader>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <iframe id={iFrameId}
                title={"Lucy"}
                src={`${CHAT_CLIENT_URL}?token=${token}`}
                style={{ marginTop: "10px" }}
                width="400px"
                height={500} />
        <ButtonsContainer>
          {!assignedEvents || (assignedEvents.length === 0)
            ? `No events assigned to team: ${user?.team}`
            : assignedEvents.map(event =>
              (<MarginButton onClick={() => sendEvent(event)}
                             key={`event-${event}`}>{SurveyActivationEventTranslations[event][user?.language]}</MarginButton>))
          }
        </ButtonsContainer>
      </div>
    </Container>
  );
};

export default ChatComponent;
