import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Button from "../ui-kit/Button";

export const Container = styled.div`
  padding: 8px;
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: center;
  margin-left:20px;
`;
export const MarginButton = styled(Button)`
  width: 200px;
  margin: 5px;
`