import { gql } from "@apollo/client";

export const TEAM_ASSIGNMENT_FOR_SURVEY = gql`
query ($surveyId: String!) {
  surveys {
    teamAssignment{
      get(surveyId: $surveyId)
    }
  }
}
`;