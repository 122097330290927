import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { RESPONSES_QUERY } from "../../api/responses/queries";
import PageHeader from "../PageHeader";
import Table from "../ui-kit/ResponsesTable";
import Autocomplete from "../ui-kit/Autocomplete";
import ButtonComponent from "../ui-kit/Button";

import {
  Container,
  TopSection,
  FiltersContainer,
  AutocompleteContainer,
  SubmitButtonSection,
} from "./styles";
import { useResponses } from "./hooks/useResponses";

const ResponsesComponent: React.FC = () => {
  const {
    filters,
    filtersOptions,
    applyFilters,
    onUpdateFilter,
    onApplyQuery,
  } = useResponses();

  const { data } = useQuery(RESPONSES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      filters: applyFilters,
      limit: 10,
      offset: 10,
    },
  });

  useEffect(() => {
    onApplyQuery();
  }, []);

  return (
    <Container>
      <PageHeader>Responses</PageHeader>
      <TopSection>
        <FiltersContainer>
          <AutocompleteContainer>
            <Autocomplete
              id="channels-autocomplete"
              placeholder="Channels"
              options={filtersOptions.channels}
              defaultValue={filters.channelIn}
              onChange={(values) => onUpdateFilter("channelIn", values)}
            />
          </AutocompleteContainer>
          <AutocompleteContainer>
            <Autocomplete
              id="departments-autocomplete"
              options={filtersOptions.departments}
              defaultValue={filters.departmentIn}
              placeholder="Departments"
              onChange={(values) => onUpdateFilter("departmentIn", values)}
            />
          </AutocompleteContainer>
          <AutocompleteContainer>
            <Autocomplete
              id="surveys-autocomplete"
              options={filtersOptions.surveys}
              defaultValue={filters.surveyIdIn}
              placeholder="Surveys"
              onChange={(values) => onUpdateFilter("surveyIdIn", values)}
            />
          </AutocompleteContainer>
          <AutocompleteContainer>
            <Autocomplete
              id="teams-autocomplete"
              options={filtersOptions.teams}
              defaultValue={filters.teamIn}
              placeholder="Teams"
              onChange={(values) => onUpdateFilter("teamIn", values)}
            />
          </AutocompleteContainer>
        </FiltersContainer>
      </TopSection>
      <SubmitButtonSection>
        <ButtonComponent width="300px" onClick={() => onApplyQuery()}>
          Apply
        </ButtonComponent>
      </SubmitButtonSection>
      <div>
        <Table data={data?.reports?.responses} />
      </div>
    </Container>
  );
};

export default ResponsesComponent;
