import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";

//contexts
import { AuthProvider } from "./context/auth";

// pages
import NotFound from "./pages/404";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import ProfilePage from "./pages/Profile";
import UsersPage from "./pages/Users";
import CreateUserPage from "./pages/CreateUser";
import ResponsesPage from "./pages/Responses";
//apollo
import apolloClient from "./apollo-client";
import SurveyComponentPage from "./pages/Survey";

//theme
import theme from "./styles/theme";
import { MATERIAL_UI_THEME } from "./material-ui-config";

import LayoutManager from "./components/LayoutManager";
import TeamProfilePage from "./pages/TeamProfile";
import CreateSurveyPage from "./pages/CreateSurvey";
import AddQuestions from "./components/Survey/Questions";
import ChatPage from "./pages/Chat";


const adjustedTheme = (ancestorTheme: any) => ({
  ...theme,
  ...ancestorTheme
});

function App() {
  return (
    <MuiThemeProvider theme={MATERIAL_UI_THEME}>
      <ThemeProvider theme={adjustedTheme}>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <BrowserRouter>
              <LayoutManager>
                <Routes>
                  <Route path="/" element={<ChatPage />} />
                  <Route path="*" element={<NotFound />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/team-profile" element={<TeamProfilePage />} />
                  <Route path="/reports" element={<HomePage />} />
                  <Route path="/responses" element={<ResponsesPage />} />
                  <Route path="/users" element={<UsersPage />} />
                  <Route path="/users/create" element={<CreateUserPage />} />
                  <Route path="/survey" element={<SurveyComponentPage />} />
                  <Route path="/survey/edit/:id" element={<CreateSurveyPage />} />
                  <Route path="/survey/add" element={<CreateSurveyPage />} />
                  <Route path="/chat" element={<ChatPage />} />
                </Routes>
              </LayoutManager>
            </BrowserRouter>
          </AuthProvider>
        </ApolloProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
