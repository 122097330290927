const colours = {
  white: "#fff",
  green: {
    primary: "#50a25d",
    secondary: "#5cb85c",
  },
  grey: {
    primary: "#616161",
    secondary: "#f1f1f1",
  },
  red: {
    primary: "#ff8280",
    secondary: "#dddbd1",
  },
};

export default colours;
