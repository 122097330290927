import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import PageHeader from "../PageHeader";
import Autocomplete from "../ui-kit/Autocomplete";

import {
  StyledAutocompleteContainer,
  StyledBoxFlex,
  StyledCollapseTable,
  StyledTableColors,
  StyledTableHeader
} from "./style";

import { useTeamProfile } from "./hooks/useTeamProfile";
import { ITeam } from "./types";

export default function CollapsibleTableTeamProfile() {
  const {
    teamDetails,
    selectedTeams,
    availableTeams,
    onUpdateSelectedTeams
  } = useTeamProfile();

  return (
    <Container>
      <PageHeader>Team Profile</PageHeader>
      <StyledBoxFlex>
        <StyledAutocompleteContainer>
          <Autocomplete
            id="teams-autocomplete"
            placeholder="Teams"
            options={availableTeams}
            defaultValue={selectedTeams}
            onChange={(values) => onUpdateSelectedTeams(values)}
          />
        </StyledAutocompleteContainer>
      </StyledBoxFlex>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableHeader />
              <StyledTableHeader align="left">Team Name</StyledTableHeader>
              <StyledTableHeader align="right">
                Number of Members
              </StyledTableHeader>
              <StyledTableHeader align="left">Num. Assigned Events</StyledTableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamDetails?.map((team, i) => {
              return <Row key={i} team={team} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

function Row(props: { team: ITeam }) {
  const { team } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{team.name}</TableCell>
        <TableCell align="right">{team.members.length}</TableCell>
        <TableCell align="right">{team.assignedEvents.length}</TableCell>
      </TableRow>
      <TableRow>
        <StyledTableColors
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <StyledCollapseTable in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Team Members
              </Typography>
              <StyledBoxFlex>
                {team.members.length > 0 ? (<Table size="small" aria-label="team-members">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Language</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <React.Fragment>
                      {team.members.map((member) => (
                        <TableRow key={member.id}>
                          <TableCell component="th" scope="row">
                            {member.id}
                          </TableCell>
                          <TableCell>{member.fullName}</TableCell>
                          <TableCell>{member.department}</TableCell>
                          <TableCell>{member.role}</TableCell>
                          <TableCell>{member.language}</TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  </TableBody>
                </Table>) : ("No members in the team")}

              </StyledBoxFlex>
            </Box>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">Assigned Events</Typography>
              {team.assignedEvents.length > 0
                ? team.assignedEvents.map(event => (
                  <p>{event}</p>)) : "No events assigned to team"}
            </Box>
          </StyledCollapseTable>
        </StyledTableColors>
      </TableRow>
    </React.Fragment>
  );
}
