import { format, fromUnixTime } from "date-fns";
import React from "react";
import {
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IGranularity } from "../../types";

import { ChartContainer } from "./styles";
import {
  getMinAndMaxDateFromChartSeries,
  getTicksByDayGranularity,
  getTicksByMonthGranularity,
  getTicksByWeekGranularity,
} from "./utils";

export interface ChartPoint {
  date: number;
  value: number;
}

export interface ChartSeries {
  data: ChartPoint[];
  seriesName: string;
}

type Props = {
  chartSeries: ChartSeries[] | undefined;
  granularity: IGranularity;
};

const seriesShapes = [
  "circle",
  "cross",
  "diamond",
  "square",
  "star",
  "triangle",
];

const seriesShapeSymbol = {
  circle: "●",
  cross: "✚",
  diamond: "◆",
  square: "■",
  star: "★",
  triangle: "▲",
};

const renderLegend = (props) => {
  const { payload } = props;
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {payload.map((entry, index) => (
        <span key={`item-${index}`} style={{ margin: "5px" }}>
          {seriesShapeSymbol[pickSeriesShape(index)] + entry.value}
        </span>
      ))}
    </div>
  );
};

const pickDataPointColor = (dataPoint: ChartPoint) => {
  const value = dataPoint.value;
  if (value >= 9.5) {
    return "#007f4e";
  } else if (value >= 9) {
    return "#72b043";
  } else if (value >= 7) {
    return "#f8cc1b";
  } else if (value >= 5) {
    return "#f37324";
  }
  return "#e12729";
};

const pickSeriesShape = (nthSeries: number): any =>
  seriesShapes[nthSeries % seriesShapes.length];

const ChartComponent: React.FC<Props> = ({ chartSeries, granularity }) => {
  if (chartSeries === undefined) {
    return null;
  }

  const { minDate, maxDate } = getMinAndMaxDateFromChartSeries(chartSeries);

  const ticks = (granularity: IGranularity) => {
    if (granularity == "DAY") {
      return getTicksByDayGranularity(minDate, maxDate);
    }
    if (granularity == "WEEK") {
      return getTicksByWeekGranularity(minDate, maxDate);
    }
    return getTicksByMonthGranularity(minDate, maxDate);
  };

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          width={400}
          height={400}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey="date"
            name="Date"
            fontFamily="monospace"
            fontSize="0.8rem"
            ticks={ticks(granularity)}
            tickFormatter={(date) =>
              format(
                fromUnixTime(date),
                granularity == "DAY" || granularity == "WEEK"
                  ? "dd-MM-yyyy"
                  : "MM-yyyy"
              )
            }
            domain={ticks(granularity)}
          />
          <YAxis
            type="number"
            dataKey="value"
            name="Value"
            fontFamily="monospace"
            fontSize="0.8rem"
            domain={[0, 10]}
          />

          {chartSeries.map((dataItem, index) => (
            <Scatter
              key={dataItem.seriesName}
              name={dataItem.seriesName}
              data={dataItem.data}
              shape={pickSeriesShape(index)}
            >
              {dataItem.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={pickDataPointColor(entry)} />
              ))}
            </Scatter>
          ))}

          <Tooltip
            cursor={{ strokeDasharray: "3 3" }}
            formatter={(value, name) => {
              switch (name) {
                case "Date":
                  return format(fromUnixTime(value as number), "dd-MM-yyyy");
                case "Value":
                  return (value as number).toFixed(2);
              }

              return "";
            }}
          />

          <Legend content={renderLegend} />
        </ScatterChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default ChartComponent;
