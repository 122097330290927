import React, { useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import some from "lodash/some";
import {
  Container,
  Form,
  FormContainer,
  FormLeft,
  FormRight,
  StyledTextBox,
  ButtonsContainer,
  StyledDiv,
} from "./styles";

import { MUTATION_REGISTER_USER } from "../../api/users/mutations";

import PageHeader from "../PageHeader";

import Button from "../ui-kit/Button";
import Select from "../ui-kit/Select";

import { USER_ROLES } from "../../enums";
import { convertEnumToOptionList } from "../../utils/common";

import useCreateUserForm from "./use-form";

const CreateUserComponent: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const userRolesOptions = useMemo(
    () => convertEnumToOptionList(USER_ROLES),
    [USER_ROLES]
  );

  const [createUser, { loading: isCreatingUser }] = useMutation(
    MUTATION_REGISTER_USER
  );

  const { form, onUpdateFormField, fieldErrors } = useCreateUserForm();

  const formIsValid =
    form.fullName &&
    form.username &&
    form.password &&
    form.confirmPassword &&
    form.team;
  const isCreateUserButtonDisabled =
    isCreatingUser || some(fieldErrors) || !formIsValid;

  const onSubmit = async () => {
    const { fullName, username, password } =
      form;

    const result = await createUser({
      variables: {
        registerUserInput: { fullName, username, password },
      },
    });
    if (!result.errors) {
      navigate("/users");
    }
  };

  return (
    <Container>
      <PageHeader>{t("createNewUser")}</PageHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <FormContainer>
          <FormLeft>
            <StyledTextBox
              width="100%"
              placeholder={t("fullName")}
              onChange={(value) => onUpdateFormField("fullName", value)}
              errorMessage={fieldErrors.fullName}
              value={form.fullName || ""}
            />
            <StyledTextBox
              width="100%"
              placeholder={t("username")}
              onChange={(value) => onUpdateFormField("username", value)}
              errorMessage={fieldErrors.username}
              value={form.username || ""}
            />
          </FormLeft>
          <FormLeft>
            <StyledTextBox
              width="100%"
              type="password"
              placeholder={t("newPassword")}
              onChange={(value) => onUpdateFormField("password", value)}
              value={form.password || ""}
            />
            <StyledTextBox
              width="100%"
              type="password"
              placeholder={t("confirmPassword")}
              onChange={(value) => onUpdateFormField("confirmPassword", value)}
              errorMessage={fieldErrors.confirmPassword}
              value={form.confirmPassword || ""}
            />
          </FormLeft>
          <FormRight>
            <StyledTextBox
              width="100%"
              placeholder={t("team")}
              onChange={(value) => onUpdateFormField("team", value)}
              errorMessage={fieldErrors.team}
              value={form.team || ""}
            />
            <StyledDiv>
              <Select
                id="select-user-role"
                options={userRolesOptions}
                placeholder={t("userRole")}
                onChange={(value) => onUpdateFormField("userRole", value)}
                value={form.userRole || ""}
              />
            </StyledDiv>
          </FormRight>
        </FormContainer>
        <ButtonsContainer>
          <Button
            width="50%"
            height="35px"
            type="submit"
            isDisabled={isCreateUserButtonDisabled}
          >
            {t("createUser")}
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};
export default CreateUserComponent;
