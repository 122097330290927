import { useMutation } from "@apollo/client";
import { Box, Modal } from "@mui/material";
import { useMemo, useState } from "react";
import { MUTATION_CREATE_SAMPLE_ANSER } from "../../../api/survey/sampleAnswers/mutation";
import { convertEnumToOptionList } from "../../../utils/common";
import PageHeader from "../../PageHeader";
import Button from "../../ui-kit/Button";
import SelectComponent from "../../ui-kit/Select";
import TextBox from "../../ui-kit/Textbox";
import {
  Form,
  FormContainer,
  ModalForm,
  ModalQuestion,
  RowContainer,
} from "../style";
import { LANGUAGES, LanguagesOption } from "../types";

function AddAnswerModal(props: {
  open: boolean;
  handleClose: any;
  questionId: number | undefined;
}) {
  const { open, handleClose, questionId } = props;
  const [language, setLanguage] = useState<LanguagesOption>("en");
  const [answerText, setAnswerText] = useState<string>("");
  const [assignedValue, setAssignedValue] = useState<number>(1);

  const languageOptions = useMemo(() => convertEnumToOptionList(LANGUAGES), []);

  const [createSampleAnswer, { loading: isSampleAnswerEditing }] = useMutation(
    MUTATION_CREATE_SAMPLE_ANSER
  );

  const onSubmitAnswer = async (e) => {
    e.preventDefault();

    await createSampleAnswer({
      variables: {
        answer: {
          questionId,
          assignedValue,
          answerText,
          language,
        },
      },
    });

    handleClose();
  };
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <ModalForm>
        <ModalQuestion>
          <PageHeader>Add Sample Answer</PageHeader>
          <Form>
            <FormContainer>
              <RowContainer>
                <SelectComponent
                  id="language-select"
                  materialProps={{ size: "small" }}
                  placeholder="Language"
                  options={languageOptions}
                  value={language}
                  onChange={setLanguage}
                ></SelectComponent>
              </RowContainer>
              <RowContainer>
                <TextBox
                  width="100%"
                  placeholder="Answer Text"
                  value={answerText}
                  onChange={setAnswerText}
                  materialProps={{ size: "small" }}
                />
              </RowContainer>
              <RowContainer>
                <TextBox
                  width="100%"
                  placeholder="Assigned Value"
                  value={assignedValue}
                  type="number"
                  materialProps={{ size: "small" }}
                  onChange={(value) => setAssignedValue(Number(value))}
                  errorMessage={
                    assignedValue > 10 || assignedValue < 1
                      ? "Value must be between 1 and 10"
                      : ""
                  }
                />
              </RowContainer>
            </FormContainer>
            <RowContainer>
              <Box>
                <Button
                  isDisabled={isSampleAnswerEditing}
                  width="30%"
                  height="35px"
                  onClick={onSubmitAnswer}
                >
                  {isSampleAnswerEditing ? "Saving" : "Save Changes"}
                </Button>
              </Box>
            </RowContainer>
          </Form>
        </ModalQuestion>
      </ModalForm>
    </Modal>
  );
}

export default AddAnswerModal;
