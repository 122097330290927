import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../context/auth";
import { LoginForm } from "../../context/auth/types";
import { useMeQuery } from "../../hooks/useMeQuery";
import { VerticalSeparator } from "../../styles/common";
import PageSpinnerComponent from "../PageSpinner";
import Button from "../ui-kit/Button";

import {
  ButtonsContainer,
  Container,
  ErrorMessage,
  FieldContainer,
  Form,
  FormContent,
  FormTitle,
  StyledInputBox
} from "./styles";

const initLoginForm = (): LoginForm => ({
  username: undefined,
  password: undefined,
});

const isFormValid = (form: LoginForm) => form.password && form.username;

const LoginComponent: React.FC = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const { user, onLogin } = useAuthContext();
  const meQueryResult = useMeQuery();

  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const [loginForm, setLoginForm] = useState<LoginForm>(initLoginForm());

  const updateFormField = (key: keyof LoginForm, value: any) => {
    setLoginError(undefined);
    setLoginForm((current) => ({ ...current, [key]: value }));
  };

  const login = async () => {
    setIsLoggingIn(true);
    const loginResult = await onLogin(loginForm);
    setIsLoggingIn(false);

    if (!loginResult.ok) {
      //TODO: Maybe show errors somehow
      console.log(loginResult.error);
      setLoginError("Failed to login"); //TODO:  This probably should be inferred from login api response
      return;
    }

    navigate("/");
  };

  useEffect(() => {
    const { data, loading } = meQueryResult;
    const shouldRedirectToHome = Boolean(!loading && data);

    if (shouldRedirectToHome) {
      navigate("/", { replace: true });
    }
  }, [meQueryResult, user, navigate]);

  if (meQueryResult.loading) {
    return <PageSpinnerComponent />;
  }

  if (meQueryResult.data) {
    return null;
  }

  const isSubmitDisabled = !isFormValid(loginForm) || isLoggingIn;

  return (
    <Container>
      {
        //** */}  <select
        //   defaultValue={i18n.language}
        //   onChange={(e) => i18n.changeLanguage(e.target.value)}
        // >
        //   {availableLanguages.map((language) => (
        //     <option key={language}>{language}</option>
        //   ))}
        // </select>
      }
      <Form
        aria-disabled={isSubmitDisabled}
        onSubmit={(e) => {
          e.preventDefault();
          login();
        }}
      >
         <FormTitle>{t("enterCredentials")}</FormTitle> 
        <FormContent>
          <FieldContainer>
            <StyledInputBox
              placeholder={t("email")}
              defaultValue={loginForm.username}
              onChange={(value) => updateFormField("username", value)}
            />
          </FieldContainer>
          <VerticalSeparator height="16px" />

          <FieldContainer>
            <StyledInputBox
              placeholder={t("password")}
              type="password"
              onChange={(value) => updateFormField("password", value)}
            />
          </FieldContainer>

          <VerticalSeparator height="32px" />
          {loginError && (
            <>
              <ErrorMessage>{loginError}</ErrorMessage>{" "}
              <VerticalSeparator height="32px" />
            </>
          )}
          <ButtonsContainer>
            <Button type="submit" width="100%" isDisabled={isSubmitDisabled}>
              {isLoggingIn ? t("logIn.loading") : t("logIn.idle")}
            </Button>
          </ButtonsContainer>
        </FormContent>
      </Form>
    </Container>
  );
};

export default LoginComponent;
