import React, { useEffect, useMemo } from "react";
import map from "lodash/map";
import { CircularProgress } from "@mui/material";
import { format, getUnixTime, startOfDay } from "date-fns";

import PageHeaderComponent from "../PageHeader";

import SelectComponent from "../ui-kit/Select";
import Autocomplete from "../ui-kit/Autocomplete";
import Table from "./components/Table";
import Chart from "./components/Chart";

import {
  RowContainer,
  WidthContainer,
  ChartContainer,
  TableContainer,
  FiltersContainer,
  TopSection,
  SubmitButtonSection,
  NoDataBanner,
  TableLoaderContainer
} from "./styles";
import { useDashboardManager } from "./hooks/useDashboardManager";
import ButtonComponent from "../ui-kit/Button";
import { CHART_DIMENSION, GRANULARITY } from "../../enums";
import { convertEnumToOptionList } from "../../utils/common";
import { ITableData } from "./types";
import DatePickerComponent from "../ui-kit/DatePicker";

import { DataSeries } from "../../types";
import PieChart from "./components/PieChart";
import BarChartComponent from "./components/BarChart";

const adaptChartDataSeries = (dataSeries: DataSeries[]) =>
  map(dataSeries, (dataItem) => ({
    ...dataItem,
    data: map(dataItem.data, (dataPoint) => ({
      ...dataPoint,
      date: getUnixTime(startOfDay(new Date(dataPoint.date)))
    }))
  }));

const HomeComponent: React.FC = () => {
  const {
    tableData,
    chartData,
    volumeData,
    scoreData,
    filters,
    granularity,
    groupBy,
    loaders,
    filtersOptions,
    onApplyQuery,
    onUpdateFilter,
    onUpdateGranularity,
    onUpdateGroupBy
  } = useDashboardManager();

  useEffect(() => {
    onApplyQuery();
  }, []);

  const granularityOptions = useMemo(
    () => convertEnumToOptionList(GRANULARITY),
    []
  );

  const groupByOptions = useMemo(
    () => convertEnumToOptionList(CHART_DIMENSION),
    []
  );

  const tableColumns = tableData?.dimensions.map((dimension) => {
    const dateFormat =
      granularity === "MONTH" || granularity === "QUARTER"
        ? "yyyy-MM"
        : "yyyy-MM-dd";
    return format(new Date(dimension), dateFormat);
  });

  const shouldShow = {
    table: Boolean(tableData),
    tableLoader: loaders.table,
    noTableDataBanner: !Boolean(tableData) && !loaders.table,
    chart: Boolean(chartData),
    chartLoader: loaders.chart,
    volume: Boolean(volumeData),
    score: Boolean(volumeData)
  };

  const chartSeries = !shouldShow.chart
    ? undefined
    : adaptChartDataSeries(chartData as DataSeries[]);

  return (
    <div>
      <PageHeaderComponent>Reports</PageHeaderComponent>
      <TopSection>
        <FiltersContainer>
          <Autocomplete
            id="channels-autocomplete"
            options={filtersOptions.channels}
            defaultValue={filters.channelIn}
            placeholder="Channels"
            onChange={(values) => onUpdateFilter("channelIn", values)}
          />
          <Autocomplete
            id="departments-autocomplete"
            options={filtersOptions.departments}
            defaultValue={filters.departmentIn}
            placeholder="Departments"
            onChange={(values) => onUpdateFilter("departmentIn", values)}
          />
          <Autocomplete
            id="surveys-autocomplete"
            options={filtersOptions.surveys}
            defaultValue={filters.surveyIdIn}
            placeholder="Surveys"
            onChange={(values) => onUpdateFilter("surveyIdIn", values)}
          />
          <Autocomplete
            id="teams-autocomplete"
            options={filtersOptions.teams}
            defaultValue={filters.teamIn}
            placeholder="Teams"
            onChange={(values) => onUpdateFilter("teamIn", values)}
          />

          <DatePickerComponent
            placeholder="Survey from"
            value={new Date(filters.surveyFrom)}
            handleDateChange={(value) =>
              onUpdateFilter("surveyFrom", value.toString())
            }
          />
          <DatePickerComponent
            placeholder="Survey to"
            value={new Date(filters.surveyTo)}
            handleDateChange={(value) =>
              onUpdateFilter("surveyTo", value.toString())
            }
          />
          <SelectComponent
            id="group-by-select"
            materialProps={{ size: "small" }}
            placeholder="Group By"
            options={groupByOptions}
            value={groupBy}
            onChange={(value) => onUpdateGroupBy(value)}
          ></SelectComponent>
          <SelectComponent
            id="Granularity"
            materialProps={{ size: "small" }}
            options={granularityOptions}
            placeholder="Granularity"
            value={granularity}
            onChange={(value) => onUpdateGranularity(value)}
          ></SelectComponent>
        </FiltersContainer>

        <SubmitButtonSection>
          <ButtonComponent
            onClick={() => onApplyQuery()}
            width="300px"
            isDisabled={loaders.table}
          >
            {loaders.table ? "Loading data" : "Apply"}
          </ButtonComponent>
        </SubmitButtonSection>
      </TopSection>
      <RowContainer>
        <WidthContainer>

        </WidthContainer>
        <WidthContainer>

        </WidthContainer>
      </RowContainer>

      <ChartContainer>
        <h3 style={{ padding: "10px", color: "#616161", marginBottom: "0px" }}>
          Avg scores over time by {groupBy}
        </h3>
        {shouldShow.chart && (
          <Chart chartSeries={chartSeries} granularity={granularity} />
        )}
        {shouldShow.chartLoader && (
          <TableLoaderContainer>
            <CircularProgress size="15vh" />
          </TableLoaderContainer>
        )}
      </ChartContainer>
      {shouldShow.volume && (
        <ChartContainer>
          <h3 style={{ padding: "10px", color: "#616161", marginBottom: "0px" }}>
            Volume of messages by {groupBy}
          </h3>
          <PieChart data={volumeData} />
        </ChartContainer>
      )}
      {shouldShow.score && (
        <ChartContainer>
          <h3 style={{ padding: "10px", color: "#616161", marginBottom: "0px" }}>
            Score by {groupBy}
          </h3>
          <BarChartComponent data={scoreData} />
        </ChartContainer>
      )}
      <TableContainer>
        {shouldShow.table && (
          <Table
            tableColumns={tableColumns}
            tableData={tableData as ITableData}
          />
        )}
        {shouldShow.tableLoader && (
          <TableLoaderContainer>
            <CircularProgress size="15vh" />
          </TableLoaderContainer>
        )}
        {shouldShow.noTableDataBanner && (
          <NoDataBanner>No data for this filter combinaton</NoDataBanner>
        )}
      </TableContainer>
    </div>
  );
};

export default HomeComponent;
