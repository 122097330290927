import colours from "./colours";
import { borders, boxShadow } from "./borders";
import spacing from "./spacing";
import { typography } from "./typography";

const toExport = {
  borders,
  boxShadow,
  colours,
  spacing,
  typography,
};

export default toExport;