import React from "react";
import PageHeader from "../PageHeader";
import { Container } from "./styles";
import Button from "../ui-kit/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UsersComponent: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container>
      <PageHeader>{t("users")}</PageHeader>
      <Button
        onClick={() => {
          navigate("/users/create");
        }}
      >
        {t("createNewUser")}
      </Button>
    </Container>
  );
};

export default UsersComponent;
