import React from "react";
import { useAuthContext } from "../../context/auth";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

import { Container, ListItemButton } from "./styles";
import LinkComponent from "../ui-kit/Link";

const NavbarComponent: React.FC = () => {
  const { user } = useAuthContext();

  if (!user) {
    return null;
  }

  return (
    <Container component="aside">
      <nav aria-label="main menu items">
        <List>
          <ListItem disablePadding>
            <LinkComponent href="/reports" shouldInheritStyles={true}>
              {(user.role !== "EMPLOYEE") && (
                <ListItemButton>
                  <ListItemText primary="Reports" />
                </ListItemButton>
              )}
            </LinkComponent>
          </ListItem>
          <ListItem disablePadding>
            <LinkComponent href="/responses" shouldInheritStyles={true}>
              {(user.role === "ADMIN" || user.role === "HR") && (
                <ListItemButton>
                  <ListItemText primary="Responses" />
                </ListItemButton>
              )}
            </LinkComponent>
          </ListItem>
          <ListItem disablePadding>
            <LinkComponent href="/team-profile" shouldInheritStyles={true}>
              {(user.role === "ADMIN" || user.role === "HR") && (
                <ListItemButton>
                  <ListItemText primary="Team Profile" />
                </ListItemButton>
              )}
            </LinkComponent>
          </ListItem>
          <ListItem disablePadding>
            <LinkComponent href="/survey" shouldInheritStyles={true}>
              {(user.role === "ADMIN" || user.role === "HR") && (
                <ListItemButton>
                  <ListItemText primary="Survey" />
                </ListItemButton>
              )}
            </LinkComponent>
          </ListItem>
          <ListItem disablePadding>
            <LinkComponent href="/users" shouldInheritStyles={true}>
              {user.role === "ADMIN" && (
                <ListItemButton>
                  <ListItemText primary="Users" />
                </ListItemButton>
              )}
            </LinkComponent>
          </ListItem>
          <ListItem disablePadding>
            <LinkComponent href="/chat" shouldInheritStyles={true}>
              {(user.role === "EMPLOYEE" || user.role === "LEAD") && (
                <ListItemButton>
                  <ListItemText primary="Chat" />
                </ListItemButton>
              )}
            </LinkComponent>
          </ListItem>
        </List>
      </nav>
      <Divider />
    </Container>
  );
};

export default NavbarComponent;
