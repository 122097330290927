import styled from "@emotion/styled";

import Link from "@mui/material/Link";

export const StyledLink = styled(Link)`
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.2s ease-out;
  color: ${({ theme }) => theme.colours.white};

  &:hover,
  &:active {
    opacity: 0.8;
  }
`;

export const InheritedStylesLink = styled(Link)`
  all: inherit;
`;
