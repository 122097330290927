import { useMutation } from "@apollo/client";
import { Box, Modal } from "@mui/material";
import { useMemo, useState } from "react";
import { MUTATION_CREATE_QUESTION } from "../../../api/questions/mutation";
import { convertEnumToOptionList } from "../../../utils/common";
import PageHeader from "../../PageHeader";
import Button from "../../ui-kit/Button";
import SelectComponent from "../../ui-kit/Select";
import TextBox from "../../ui-kit/Textbox";
import {
  Form,
  FormContainer,
  Label,
  ModalForm,
  ModalQuestion,
  RowContainer
} from "../style";
import { QUESTION_TYPE } from "../types";
import useSurveyFrom from "../useSuerveyForm";

function AddQuestionsModal(props: {
  open: boolean;
  handleClose: any;
  lastQuestionOrder: number;
  id: string | undefined;
}) {
  const [duplcateOrder, setDuplicateOrder] = useState<boolean>(false);
  const { open, handleClose, lastQuestionOrder, id } = props;
  const [questionChange, setQuestionChange] = useState<boolean>(true);

  const { questionType, questionOrder, onUpdateQuestionBy, onQuestionOrder } =
    useSurveyFrom();

  const questionsnOptions = useMemo(
    () => convertEnumToOptionList(QUESTION_TYPE),
    []
  );

  const [createQuestion, { loading: isQustionEditing }] = useMutation(
    MUTATION_CREATE_QUESTION
  );
  const isEditingForm = isQustionEditing;

  const onSubmitQuestion = async (e) => {
    e.preventDefault();
    setDuplicateOrder(false);
    const questionChangeUpdate = questionChange
      ? lastQuestionOrder + 1
      : questionOrder;

    const result = await createQuestion({
      variables: {
        question: {
          questionOrder: questionChangeUpdate,
          questionType,
          surveyId: id,
        },
      },
    });
    setQuestionChange(true);

    result.errors ? setDuplicateOrder(true) : handleClose();
  };
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <ModalForm>
        <ModalQuestion>
          <PageHeader>Add Question</PageHeader>
          <Form>
            <FormContainer>
              <RowContainer>
                <Label>Question Order:</Label>
                <TextBox
                  width="100%"
                  type="number"
                  placeholder="Question Order"
                  value={
                    questionChange
                      ? lastQuestionOrder + 1 || 0
                      : questionOrder || 0
                  }
                  onChange={(value) => {
                    onQuestionOrder(Number(value));
                    setQuestionChange(false);
                  }}
                  errorMessage={
                    duplcateOrder
                      ? `Order Number is allredy in use , try with ${
                          lastQuestionOrder + 1
                        } or above `
                      : ""
                  }
                  materialProps={{ size: "small" }}
                />
              </RowContainer>
              <RowContainer>
                <Label>Question Type:</Label>
                <SelectComponent
                  id="question-type-select"
                  materialProps={{ size: "small" }}
                  placeholder="Question Type"
                  options={questionsnOptions}
                  value={questionType || ""}
                  onChange={(value) => {
                    onUpdateQuestionBy(value);
                  }}
                ></SelectComponent>
              </RowContainer>
            </FormContainer>
            <RowContainer>
              <Box>
                <Button
                  isDisabled={isEditingForm}
                  width="30%"
                  height="35px"
                  onClick={(e) => onSubmitQuestion(e)}
                >
                  {isQustionEditing ? "Saving" : "Save Changes"}
                </Button>
              </Box>
            </RowContainer>
          </Form>
        </ModalQuestion>
      </ModalForm>
    </Modal>
  );
}

AddQuestionsModal.propTypes = {};

export default AddQuestionsModal;
