import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth";
import { HorizontalSeparator } from "../../styles/common";
import Link from "../ui-kit/Link";

import {
  Center,
  Container,
  LeftSection,
  LogoutIcon,
  UserIcon,
  RightSection,
  UserProfileLink,
  UserProfileLinkInner,
} from "./styles";

const HeaderComponent: React.FC = () => {
  const navigate = useNavigate();
  const { user, onLogout } = useAuthContext();

  const handleLogout = () => {
    onLogout();
    window.requestAnimationFrame(() => {
      navigate("/login", { replace: true });
    });
  };

  return (
    <Container>
      <LeftSection>
        <Link href="/">AI e-NPS Platform</Link>
      </LeftSection>
      <Center />
      {user && (
        <RightSection>
          <UserProfileLink href={"/profile"}>
            <UserProfileLinkInner title="User Profile">
              <UserIcon />
              <HorizontalSeparator width="16px" />
              <span>{user.fullName}</span>
            </UserProfileLinkInner>
          </UserProfileLink>
          <LogoutIcon onClick={() => handleLogout()} name="Logout" />
        </RightSection>
      )}
    </Container>
  );
};

export default HeaderComponent;
