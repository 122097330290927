export const typography = {
  text: `
    font-family: Roboto, Regular;
    font-size: 13px;
    font-weight: normal;
  `,
  boldText: `
    font-family: Roboto, Regular;
    font-size: 13px;
    font-weight: bold;
  `,
  extraSmallText: `
  font-family: Roboto, Regular;
  font-size: 10px;
`,
  smallText: `
  font-family: Roboto, Regular;
  font-size: 11px;
`,
  label: `
    font-family: Roboto, Regular;
    font-size: 13px;
    font-weight: bold;
  `,
  bigLabel: `
    font-family: Roboto, Regular;
    font-size: 16px;
    line-height: 16px;
  `,
  title: `
    font-family: Roboto, Regular;
    font-size: 24px;
  `,
};
