import React from "react";
import {
  Cell, LabelList, Legend,
  Pie, PieChart,
  ResponsiveContainer, Tooltip
} from "recharts";

import { ChartContainer } from "./styles";

type Props = {
  data: PieChartData[] | undefined;
};

export interface PieChartData {
  group: string;
  volume: number;
}

const colors = [
  "#D9ED92",
  "#34A0A4",
  "#B5E48C",
  "#168AAD",
  "#99D98C",
  "#1A759F",
  "#76C893",
  "#1E6091",
  "#52B69A",
  "#184E77"
];
const PieChartComponent: React.FC<Props> = ({ data }) => {
  if (data === undefined) {
    return null;
  }
  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Tooltip />
          <Pie data={data} dataKey="volume" nameKey="group" cx="50%" cy="50%" label>
            {
              data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))
            }
          </Pie>
          <Legend verticalAlign="bottom" height={36} />
        </PieChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default PieChartComponent;
