import { useMutation } from "@apollo/client";
import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import PageHeader from "../../PageHeader";
import Button from "../../ui-kit/Button";
import { MUTATION_OVERRIDE_ANSWER } from "../../../api/responses/mutations";
import { TextBox } from "../../ui-kit/Textbox/styles";
import { Form, FormContainer, ModalForm, ModalQuestion, RowContainer } from "../../Survey/style";

const isOverrideValid = (v: number) => v >= 1 && v <= 10;

function OverrideModal(props: {
  open: boolean;
  handleClose: any;
  responseId: number | string | undefined;
  currentAnswer: string| number;
}) {
  const { open, handleClose, responseId, currentAnswer } = props;
  const [answerOverride, setAnswerOverride] =
    useState<string>("");

  const [overrideAnswerMutation, { loading: isAnswerBeingOverriden }] = useMutation(
    MUTATION_OVERRIDE_ANSWER
  );
  const onOverrideAnswer = async (e) => {
    e.preventDefault();
    const override: number = answerOverride === "" ? 0 : parseInt(answerOverride);
    if (!isOverrideValid(override)) {
      setAnswerOverride("");
      return;
    }
    await overrideAnswerMutation({
      variables: {
        responseId,
        override
      }
    });

    handleClose();
  };


  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <ModalForm>
        <ModalQuestion>
          <PageHeader>Override Answer</PageHeader>
          <Form>
            <FormContainer>
              <RowContainer>
                Current answer: {currentAnswer}
                <TextBox placeholder="Override value with 1-10"
                         width="100%"
                         value={answerOverride}
                         onChange={(e) => setAnswerOverride(e.target.value)}
                />
              </RowContainer>
            </FormContainer>
            <RowContainer>
              <Box>
                <Button
                  isDisabled={isAnswerBeingOverriden}
                  width="30%"
                  height="35px"
                  onClick={onOverrideAnswer}
                >
                  {isAnswerBeingOverriden ? "Saving" : "Override"}
                </Button>
              </Box>
            </RowContainer>
          </Form>
        </ModalQuestion>
      </ModalForm>
    </Modal>
  );
}

export default OverrideModal;
