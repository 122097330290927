import { gql } from "@apollo/client";

export const DASHBOARD_TABLE_DATA_QUERY = gql`
  query ReportQueries($filters: ReportFilters!, $granularity: Granularity!) {
    reports {
      table(filters: $filters, granularity: $granularity) {
        totalGrade
        totalVolume
        dimensions
        overallGrade
        chatVolume
        nps
        totalNps
      }
    }
  }
`;

export const FILTER_VALUES_QUERY = gql`
  query {
    reports {
      filterValues {
        channels
        departments
        surveys
        teams
      }
    }
  }
`;
export const DASHBOARD_CHART_DATA_QUERY = gql`
  query ReportQueries(
    $filters: ReportFilters!
    $groupBy: ChartDimension!
    $granularity: Granularity!
  ) {
    reports {
      chart(filters: $filters, groupBy: $groupBy, granularity: $granularity) {
        data {
          date
          value
        }
        seriesName
      }
    }
  }
`;

export const DASHBOARD_VOLUME_DATA_QUERY = gql`
query VolumeReport($filters: ReportFilters!, $groupBy: ChartDimension!){
  reports{
    volume(filters: $filters, groupBy: $groupBy){
      group
      volume
    }
  }
}
`


export const DASHBOARD_SCORE_DATA_QUERY = gql`
query ScoreReport($filters: ReportFilters!, $groupBy: ChartDimension!){
  reports{
    score(filters: $filters, groupBy: $groupBy){
      group
      nps
    }
  }
}
`