import { ButtonProps } from "@mui/material";
import React, { CSSProperties, PropsWithChildren } from "react";
import { Button } from "./styles";

type Props = PropsWithChildren<{
  width?: string;
  height?: string;
  isDisabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  materialProps?: ButtonProps;
}>;

const ButtonComponent: React.FC<Props> = ({
  children,
  type,
  onClick,
  width,
  height,
  isDisabled,
  materialProps,
}) => (
  <Button
    type={type || "button"}
    width={width}
    height={height}
    variant="contained"
    disabled={isDisabled}
    onClick={onClick}
    {...materialProps}
  >
    {children}
  </Button>
);

export default ButtonComponent;
