const set = (key: string, value: any) => {
  const stored = JSON.stringify({ value });
  localStorage.setItem(key, stored);
};

const get = (key: string) => {
  const item = localStorage.getItem(key);

  if (item === null) {
    return null;
  }

  try {
    const { value } = JSON.parse(item);
    return value;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const remove = (key: string) => localStorage.removeItem(key);

const toExport = {set, get ,remove};
export default toExport;
