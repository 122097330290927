import React from "react";

import { Container, RefreshIcon } from "./styles";

const PageSpinnerComponent: React.FC = () => {
  return (
    <Container>
      <RefreshIcon />
    </Container>
  );
};

export default PageSpinnerComponent;
