import { IGranularity } from "./components/Home/types";
import { UserRole, ChartDimension } from "./types";

export const USER_ROLES: Record<UserRole, UserRole> = {
  ADMIN: "ADMIN",
  EMPLOYEE: "EMPLOYEE",
  HR: "HR",
  LEAD: "LEAD",
};

export const GRANULARITY: Record<IGranularity, IGranularity> = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  QUARTER: "QUARTER",
};

export const CHART_DIMENSION: Record<ChartDimension, ChartDimension> = {
  DATE: "DATE",
  DEPARTMENT: "DEPARTMENT",
  TEAM: "TEAM",
};
