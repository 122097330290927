import { gql } from "@apollo/client";

export const MUTATION_CREATE_QUESTION= gql`
  mutation ($question: CreateQuestion!) {
    surveys {
      questions {
        create(question: $question) {
          id
          questionType
          questionOrder
          surveyId
        }
      }
    }
  }
`;
