import styled from "@emotion/styled";
import $ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";

export const Container = styled(Box)`
  background-color: ${({ theme }) => theme.colours.grey.secondary};
  height: 100vh;
  width: 250px;
  position: fixed;
  left: 0px;
  top: 50px;
`;

export const ListItemButton = styled($ListItemButton)``;

export const List = styled.ul`
  list-style-type: none;
  display: block;
  padding: 4px;
  margin: 0px;
`;

export const Item = styled.li`
  height: 42px;
  border-radius: 4px;
  border: 1px solid grey;
  margin-bottom: 2px;
  display: flex;
  justify-content: left;
  padding-left: 8px;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colours.green.secondary};
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  &:hover {
    border: 1px solid black;
    background-color: ${({ theme }) => theme.colours.green.secondary};
    color: white;
  }
`;
