import { gql } from "@apollo/client";

export const MUTATION_ASSIGN_TEAMS_FOR_SURVEY = gql`
  mutation ($surveyId: String!, $teams: [String!]!) {
    surveys {
      teamAssignment {
        set(surveyId: $surveyId, teams: $teams)
      }
    }
  }
`;
