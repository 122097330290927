import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { StyledTableSortLabel } from "./styles";
import { QuestionType } from "../../../types";
import { useAuthContext } from "../../../context/auth";
import Button from "../../ui-kit/Button";
import EditIcon from "@mui/icons-material/Edit";
import OverrideModal from "../../Responses/OverrideModal";
import { useState } from "react";

interface Data {
  id: number;
  department: string;
  userInput: string;
  determinedValue: number;
  questionText: string;
  questionType: QuestionType;
  responseTime: string;
  surveyId: string;
  team: string;
  userFullName: string;
  answerAccepted: boolean;
  answerOverride: number;
}

function createData(
  id: number,
  department: string,
  userInput: string,
  determinedValue: number,
  questionText: string,
  questionType: QuestionType,
  responseTime: string,
  surveyId: string,
  team: string,
  userFullName: string,
  answerAccepted: boolean,
  answerOverride: number
): Data {
  return {
    id,
    department,
    userInput,
    determinedValue,
    questionText,
    questionType,
    responseTime,
    surveyId,
    team,
    userFullName,
    answerAccepted,
    answerOverride
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [

  // {
  //   id: "responseTime",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Response time"
  // },
  {
    id: "surveyId",
    numeric: false,
    disablePadding: false,
    label: "Survey Id"
  },
  {
    id: "team",
    numeric: false,
    disablePadding: false,
    label: "Team"
  },
  {
    id: "userFullName",
    numeric: false,
    disablePadding: false,
    label: "Full name"
  },
  {
    id: "questionType",
    numeric: false,
    disablePadding: false,
    label: "Question Type"
  },
  {
    id: "questionText",
    numeric: false,
    disablePadding: false,
    label: "Question text"
  },
  {
    id: "userInput",
    numeric: false,
    disablePadding: false,
    label: "User Input"
  },
  {
    id: "determinedValue",
    numeric: false,
    disablePadding: false,
    label: "Determined Value"
  }
];

const headCellsForAdmin: readonly HeadCell[] = [
  ...headCells, {
    id: "answerOverride",
    numeric: true,
    disablePadding: false,
    label: "Value override"
  }
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { user } = useAuthContext();

  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {(user?.role === "ADMIN" ? headCellsForAdmin : headCells).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <StyledTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TableProps {
  data: any[];
}

export default function TableComponent(props: TableProps) {
  const { user } = useAuthContext();

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("responseTime");
  const [selected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [overrideAnswerFor, setOverrideAnswerFor] = React.useState<number | string>(0);
  const [currentAnswer, setCurrentAnswer] = React.useState<string | number>("");
  const [openOverrideModal, setOpenOverrideModal] = useState<boolean>(false);

  const { data } = props;
  let rows;

  if (data) {
    rows = data.map((row) =>
      createData(
        row.id,
        row.department,
        row.userInput,
        row.determinedValue,
        row.questionText,
        row.questionType,
        row.responseTime,
        row.surveyId,
        row.team,
        row.userFullName,
        row.answerAccepted,
        row.answerOverride
      )
    );
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOverrideModalClose = (responseId: number | string, overridenValue: number) => {
    rows?.filter(x => x.id === responseId)?.forEach(y => y.answerOverride = overridenValue);
    setOpenOverrideModal(false);
  };
  const onOverrideClick = (responseId: number | string, currentAnswer: string | number) => {
    setOverrideAnswerFor(responseId);
    setCurrentAnswer(currentAnswer);
    setOpenOverrideModal(true);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  if (!data) return null;

  return (
    <Box sx={{ width: "100%" }}>
      <OverrideModal
        open={openOverrideModal}
        handleClose={handleOverrideModalClose}
        responseId={overrideAnswerFor}
        currentAnswer={currentAnswer}
      />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell align="left">{row.surveyId}</TableCell>
                      <TableCell align="left">{row.team}</TableCell>
                      <TableCell align="left">{row.userFullName}</TableCell>
                      <TableCell align="left">{row.questionType}</TableCell>
                      <TableCell align="left" size="medium">{row.questionText}</TableCell>
                      <TableCell align="left">{row.userInput}</TableCell>
                      <TableCell align="left"
                                 size="small">{user?.role !== "ADMIN"
                        ? (row.answerOverride || row.determinedValue)
                        : row.determinedValue}
                      </TableCell>
                      {user?.role === "ADMIN" ? (
                        <TableCell align="left" size="small">Current
                          Override: {row.answerOverride || "None"} <Button
                            onClick={() => onOverrideClick(row.id, row.userInput)}><EditIcon /></Button></TableCell>) : ""}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
