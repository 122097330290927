import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import {
  GET_AVAILABLE_TEAMS,
  GET_TEAM_DETAILS,
} from "../../../api/teamProfile/queries";
import { Option, OptionValue } from "../../../types";
import { ITeam } from "../types";

interface Result {
  selectedTeams: string[];
  availableTeams: Option[];
  teamDetails: ITeam[] | undefined;
  onUpdateSelectedTeams: (values: OptionValue[]) => void;
}

const convertTeamNameToFilterOption = (name: string): Option => {
  return { value: name, label: name };
};

export const useTeamProfile = (): Result => {
  const [selectedTeams, setSelectedTeams] = useState<string[]>([]);

  const { data: availableTeamsData } = useQuery(GET_AVAILABLE_TEAMS, {
    fetchPolicy: "network-only",
  });

  const [
    queryTeamDetails,
    { data: teamDetailsData },
  ] = useLazyQuery(GET_TEAM_DETAILS, { fetchPolicy: "network-only" });

  const onUpdateSelectedTeams = (values: OptionValue[]) => {
    setSelectedTeams(values as string[]);
  };


  const teamDetails: ITeam[] | undefined = teamDetailsData?.teams?.details;
  const availableTeams: Option[] = useMemo(() => {
    return (
      availableTeamsData?.teams?.availableTeams.map(
        convertTeamNameToFilterOption
      ) || []
    );
  }, [availableTeamsData]);
  useEffect(() => {
    queryTeamDetails({
      variables: { selectedTeams },
    });
  }, [queryTeamDetails, selectedTeams]);

  return {
    teamDetails,
    selectedTeams,
    availableTeams,
    onUpdateSelectedTeams,
  };
};
