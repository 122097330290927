import { gql } from "@apollo/client";

export const GET_TEAM_DETAILS = gql`
query SelectedTeamsQueries($selectedTeams: [String!]!) {
  teams {
    details(selectedTeams: $selectedTeams) {
      name
      members {
        id
        fullName
        department
        role
        language
      }
      assignedEvents
    }
  }
}
`;
export const GET_AVAILABLE_TEAMS = gql`
  query {
    teams {
      availableTeams
    }
  }
`;

export const GET_EVENTS_FOR_TEAM = gql`
query EventsAssignedToTeam($team: String!) {
  teams{
    assignedEvents(team:$team)
  }
}
`;