import colours from "./colours";

export const borders = {
  primary: `1px solid ${colours.grey.primary}`,
  light: `1px solid #eeeeee`,
  highlighted: `1px solid ${colours.green.primary}`,
};

export const boxShadow = {
  light: `0px 0px 14px 0px ${colours.grey.primary}`,
  modals: `0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)`,
};
