import { PropsWithChildren } from "react";
import { useAuthContext } from "../../context/auth";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";
import { Container, Content } from "./styles";

const LayoutManagerComponent: React.FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuthContext();

  if (!user) {
    return (
      <Container>
        <Header />
        {children}
        <Footer />
      </Container>
    );
  }

  return (
    <Container>
      <Header />
      <Navbar />
      <Content>{children}</Content>
      <Footer />
    </Container>
  );
};

export default LayoutManagerComponent;
