import { useState } from "react";
import { User } from "../../types";

interface UserProfileForm {
  fullName?: string | undefined;
  language?: string | undefined;
  password?: string | undefined;
  confirmPassword?: string | undefined;
}
type FieldErrors = Record<keyof UserProfileForm, string | undefined>;
type UpdateFieldFn = (key: keyof UserProfileForm, value: any) => void;

interface Result {
  form: UserProfileForm;
  onUpdateFormField: UpdateFieldFn;
  syncForm: (entity: User) => void;
  fieldErrors: FieldErrors;
}

const initUserProfileForm = (user: User | undefined): UserProfileForm => ({
  fullName: user?.fullName,
  language: user?.language,
});

const getFieldErrors = (form: UserProfileForm): FieldErrors => {
  const { fullName, language, password, confirmPassword } = form;

  const confirmPasswordError =
    Boolean(password) && password !== confirmPassword
      ? "Password does not match"
      : undefined;

  const fullNameError = fullName === "" ? "Field cannot be empty" : undefined;
  const languageError = language === "" ? "Field cannot be empty" : undefined;

  return {
    fullName: fullNameError,
    language: languageError,
    password: undefined,
    confirmPassword: confirmPasswordError,
  };
};

function useUserProfileForm(): Result {
  const [form, setForm] = useState<UserProfileForm>(
    initUserProfileForm(undefined)
  );

  const onUpdateFormField: UpdateFieldFn = (key, value) =>
    setForm((current) => ({ ...current, [key]: value }));

  const syncForm = (entity: User) => {
    setForm(initUserProfileForm(entity));
  };

  const fieldErrors = getFieldErrors(form);

  return {
    form,
    onUpdateFormField,
    syncForm,
    fieldErrors,
  };
}

export default useUserProfileForm;
