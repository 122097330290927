import { useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { SURVEY_QUERY } from "../../api/survey/queries";
import { QuestionType } from "../../types";
import {
  DayOfWeek,
  ISurveyData,
  QUESTION_TYPE,
  SELECT_ACTIVATION_EVENT,
  SELECT_DAYS_IN_WEEK,
  Survey,
  SurveyActivationEvent
} from "./types";

interface EditSurveyForm {
  surveyId: string | undefined;
  description: string | undefined;
  dateCreated: string | undefined;
  canSubmitMultiple:boolean;
  activeFrom: string;
  activeTo: string;
  activateOnDays:DayOfWeek;
  questionType:QuestionType;
  activateFromHour: number;
  activateToHour: number;
  questionOrder:number | undefined
}

type FieldErrors = Record<keyof EditSurveyForm, string | undefined>;
type UpdateFieldFn = (key: keyof EditSurveyForm, value: any) => void;

interface Result {
  form: EditSurveyForm;
  onUpdateFormField: UpdateFieldFn;
  onUpdateActivationBy: (activationEvent: SurveyActivationEvent[]) => void;
  onUpdateDaysBy: (daysBy: DayOfWeek[]) => void;
  syncForm: (entity: Survey) => void;
  onCanSubmitMultiple: (canSubmit: boolean)=>void;
  onQuestionOrder: (questionOrder: number)=>void;
  onUpdateQuestionBy:(questionType:QuestionType)=>void;
  canSubmitMultiple:boolean;
  fieldErrors: FieldErrors;
  activateOnEvents:SurveyActivationEvent[];
  activateOnDays:DayOfWeek[];
  questionType:QuestionType;
  availableTeams: ISurveyData[];
  questionOrder:number
}

const initSurveyForm = (survey: Survey | undefined): EditSurveyForm => ({
  surveyId: survey?.id,
  description: survey?.description,
  dateCreated: survey?.dateCreated,
  canSubmitMultiple:true,
  activeFrom: "2023-01-01",
  activeTo: "2024-12-12",
  activateOnDays:SELECT_DAYS_IN_WEEK.MONDAY,
  activateFromHour: 0,
  activateToHour: 23,
  questionType:QUESTION_TYPE.FREE_TEXT,
  questionOrder:0
});

const getFieldErrors = (form: EditSurveyForm): FieldErrors => {
  const { description, surveyId, activeTo, activeFrom } = form;

  const fullNameError =
    description === "" ? "Field cannot be empty" : undefined;
  const FullsurveyFromError =
  activeFrom === "" ? "Field cannot be empty" : undefined;
  const FullsurveyToErorr =
  activeTo === "" ? "Field cannot be empty" : undefined;
  const FullsurveyNameErorr = surveyId === "" ? "Field cannot be empty" : undefined;
  
  return {
    description: fullNameError,
    surveyId: FullsurveyNameErorr,
    dateCreated: undefined,
    activeTo: FullsurveyToErorr,
    activeFrom: FullsurveyFromError,
    activateFromHour: undefined,
    activateToHour: undefined,
    activateOnDays:undefined,
    canSubmitMultiple:undefined,
    questionType:undefined,
    questionOrder:undefined
    
  };
};

export function useSurveyFrom(): Result {
  const [form, setForm] = useState<EditSurveyForm>(initSurveyForm(undefined));
  const [activateOnDays, setActivateOnDays] = useState<DayOfWeek[]>([SELECT_DAYS_IN_WEEK.MONDAY]);
  const [questionType, setQuestionType] = useState<QuestionType>(QUESTION_TYPE.FREE_TEXT);
  const [questionOrder, setQuestionOrder] = useState<number>(1);

  const [activateOnEvents, setActivateOnEvents] = useState<SurveyActivationEvent[]>(
    [SELECT_ACTIVATION_EVENT.USER_LOGGED_IN]
  );
  const [canSubmitMultiple, setCanSubmitMultiple] = useState<boolean>(false);


  const onUpdateFormField: UpdateFieldFn = (key, value) =>
    setForm((current) => ({ ...current, [key]: value }));

  const syncForm = (entity: Survey) => {
    setForm(initSurveyForm(entity));
  };
  const { data: availableTeamsData } = useQuery(SURVEY_QUERY, {
    fetchPolicy: "network-only",
  });

  const fieldErrors = getFieldErrors(form);

  const availableTeams: ISurveyData[] = useMemo(() => {
    return availableTeamsData?.surveys?.allAvailable || [];
  }, [availableTeamsData]);
  
  const onCanSubmitMultiple = (canSubmitMultiple: boolean) => {    
    setCanSubmitMultiple(canSubmitMultiple);
  };

  const onQuestionOrder = (questionOrder: number) => { 
    if(questionOrder < 0) return   
    setQuestionOrder(questionOrder);
  };
  const onUpdateDaysBy = (activateOnDays: DayOfWeek[]) => {
    setActivateOnDays(activateOnDays);
  };
  const onUpdateActivationBy = (activateOnEvents: SurveyActivationEvent[]) => {
    setActivateOnEvents(activateOnEvents);
  };
  const onUpdateQuestionBy = (questionType: QuestionType) => {
    setQuestionType(questionType);
  };

  return {
    form,
    canSubmitMultiple,
    activateOnDays,
    activateOnEvents,
    availableTeams,
    questionType,
    questionOrder,
    onCanSubmitMultiple,
    onUpdateFormField,
    onUpdateActivationBy,
    syncForm,
    onQuestionOrder,
    onUpdateQuestionBy,
    onUpdateDaysBy,
    fieldErrors,
  };
}

export default useSurveyFrom;
