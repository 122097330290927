import { useState } from "react";
import { User, UserRole } from "../../types";

interface CreateUserForm {
  fullName?: string | undefined;
  username?: string | undefined;
  password?: string | undefined;
  confirmPassword?: string | undefined;
  team?: string | undefined;
  userRole?: UserRole | undefined;
}

type UpdateFieldFn = (key: keyof CreateUserForm, value: any) => void;
type FieldErrors = Record<keyof CreateUserForm, string | undefined>;

const initCreateUserForm = (user: User | undefined): CreateUserForm => ({
  fullName: user?.fullName,
  username: user?.username,
});

interface Result {
  form: CreateUserForm;
  onUpdateFormField: UpdateFieldFn;
  fieldErrors: FieldErrors;
}

const getFieldErrors = (form: CreateUserForm): FieldErrors => {
  const { fullName, username, password, confirmPassword, team } = form;

  const confirmPasswordError =
    Boolean(password) && password !== confirmPassword
      ? "Password does not match"
      : undefined;

  const fullNameError = fullName === "" ? "Field cannot be empty" : undefined;
  const usernameError = username === "" ? "Field cannot be empty" : undefined;
  const teamError = team === "" ? "Field cannot be empty" : undefined;

  return {
    fullName: fullNameError,
    password: undefined,
    confirmPassword: confirmPasswordError,
    username: usernameError,
    team: teamError,
    userRole: undefined,
  };
};

function useCreateUserForm(): Result {
  const [form, setForm] = useState<CreateUserForm>(
    initCreateUserForm(undefined)
  );

  const onUpdateFormField: UpdateFieldFn = (key, value) =>
    setForm((current) => ({ ...current, [key]: value }));

  const fieldErrors = getFieldErrors(form);

  return {
    form,
    onUpdateFormField,
    fieldErrors,
  };
}

export default useCreateUserForm;
