import { flatMap, map, maxBy, minBy, times } from "lodash";
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  fromUnixTime,
  getUnixTime,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { ChartSeries } from ".";

export const getMinAndMaxDateFromChartSeries = (
  chartSeries: ChartSeries[]
): { minDate: number; maxDate: number } => {
  const allDataPoints = flatMap(chartSeries, "data");

  const minDateChartPoint = minBy(allDataPoints, "date");
  const maxDateChartPoint = maxBy(allDataPoints, "date");

  return {
    minDate: minDateChartPoint?.date || 0,
    maxDate: maxDateChartPoint?.date || 0,
  };
};

//TODO: When granularity gets introduced, we might refactor this so it fits both usecases
export const getTicksByDayGranularity = (minDate: number, maxDate: number) => {
  const offsetDays = 3;

  const firstTickDate = addDays(
    startOfDay(fromUnixTime(minDate)),
    offsetDays * -1
  );
  const lastTickDate = addDays(startOfDay(fromUnixTime(maxDate)), offsetDays);
  const differenceBetweenFirstAndLastTickDates = differenceInDays(
    lastTickDate,
    firstTickDate
  );

  const allDays = map(times(differenceBetweenFirstAndLastTickDates), (n) =>
    addDays(firstTickDate, n)
  );

  return map(allDays, getUnixTime);
};

export const getTicksByMonthGranularity = (minDate: number, maxDate) => {
  const offsetMonths = 2;
  const firstTickDate = addMonths(
    startOfMonth(fromUnixTime(minDate)),
    offsetMonths * -1
  );
  const lastTickDate = addMonths(
    startOfMonth(fromUnixTime(maxDate)),
    offsetMonths
  );
  const differenceBetweenFirstAndLastTickDates = differenceInMonths(
    lastTickDate,
    firstTickDate
  );
  const allDays = map(times(differenceBetweenFirstAndLastTickDates), (n) =>
    addMonths(firstTickDate, n)
  );

  return map(allDays, getUnixTime);
};

export const getTicksByWeekGranularity = (minDate: number, maxDate) => {
  const offsetWeeks = 2;
  const firstTickDate = addWeeks(
    startOfMonth(fromUnixTime(minDate)),
    offsetWeeks * -1
  );
  const lastTickDate = addWeeks(
    startOfMonth(fromUnixTime(maxDate)),
    offsetWeeks
  );
  const differenceBetweenFirstAndLastTickDates = differenceInWeeks(
    lastTickDate,
    firstTickDate
  );
  const allDays = map(times(differenceBetweenFirstAndLastTickDates), (n) =>
    addWeeks(firstTickDate, n)
  );

  return map(allDays, getUnixTime);
};
