import { gql } from "@apollo/client";
export const RESPONSES_QUERY = gql`
  query ReportQueries(
    $filters: ReportFilters!
    $limit: Float!
    $offset: Float!
  ) {
    reports {
      responses(filters: $filters, limit: $limit, offset: $offset) {
        id
        department
        determinedValue
        questionText
        questionType
        responseTime
        surveyId
        team
        userFullName
        userInput
        answerAccepted
        answerOverride
      }
    }
  }
`;
