import { gql } from "@apollo/client";

export const MUTATION_EDIT_SURVEY = gql`
  mutation ($survey: EditSurvey!) {
    surveys {
      edit(survey: $survey) {
        canSubmitMultiple
        description
        id
      }
    }
  }
`;
export const MUTATION_CREATE_SURVEY = gql`
  mutation ($survey: CreateSurvey!) {
    surveys {
      create(survey: $survey) {
        canSubmitMultiple
        dateCreated
        description
        id
      }
    }
  }
`;
export const MUTATION_ACTIVATION_CREATE_SURVEY = gql`
  mutation ($activation: CreateActivation!) {
    surveys {
      activation {
        create(activation: $activation) {
          activateFromHour
          activateOnDays
          activateFromHour
          activateToHour
          surveyId
        }
      }
    }
  }
`;
export const MUTATION_ACTIVATION_EDIT_SURVEY = gql`
  mutation ($activation: EditActivation!) {
    surveys {
      activation {
        edit(activation: $activation) {
          activateFromHour
          activateFromHour
          activateToHour
          surveyId
        }
      }
    }
  }
`;
