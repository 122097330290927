import { useState, createContext, PropsWithChildren, useContext } from "react";
import { BASE_API_URL } from "../../constants";

//types
import { User } from "../../types";
import { AuthContextValue, LoginForm, LoginResult } from "./types";

//utils
import {
  fetchRefreshToken,
  getRefreshTokenCountdownMilliseconds,
  removeAuthMetadata,
  storeAuthMetadata,
} from "../../utils/auth";

const AuthContext = createContext({} as AuthContextValue);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [refreshTokenTimeoutId, setRefreshTokenTimeoutId] = useState<
    NodeJS.Timeout | undefined
  >(undefined);

  const handleRefreshTokenCallback = async () => {
    const newToken = await fetchRefreshToken();

    if (!newToken) {
      return;
    }

    handleAuthTokenFlow(newToken, new Date());
  };

  const scheduleRefreshTokenTimeout = (refreshCountdownMilliseconds: number) =>
    setTimeout(handleRefreshTokenCallback, refreshCountdownMilliseconds);

  const handleAuthTokenFlow = (token: string, createdAt: Date) => {
    storeAuthMetadata(token, createdAt);
    const refreshCountdownMilliseconds = getRefreshTokenCountdownMilliseconds();

    if (refreshCountdownMilliseconds <= 0) {
      return;
    }

    const timeoutId = scheduleRefreshTokenTimeout(refreshCountdownMilliseconds);

    setRefreshTokenTimeoutId(timeoutId);
  };

  const onLogout = () => {
    clearTimeout(refreshTokenTimeoutId);
    removeAuthMetadata();
    setUser(undefined);
  };

  const onLogin = async (form: LoginForm): Promise<LoginResult> => {
    const { username, password } = form;

    const body = {
      username,
      password,
    };

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    try {
      const loginResponse = await fetch(`${BASE_API_URL}/api/login`, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });

      const responseBody = await loginResponse.text();

      if (!loginResponse.ok) {
        throw new Error(responseBody || "Some error happened");
      }

      handleAuthTokenFlow(responseBody, new Date());
    } catch (error) {
      return {
        ok: false,
        error: String(error),
      };
    }

    return { ok: true };
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        refreshTokenTimeoutId,
        setUser,
        onLogin,
        onLogout,
        handleAuthTokenFlow,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
