import { gql } from "@apollo/client";

export const MUTATION_CREATE_VARIATION = gql`
  mutation ($variation: CreateVariation!) {
    surveys {
      questions {
        variations {
          create(variation: $variation) {
            id
            questionId
            questionLanguage
            questionText
          }
        }
      }
    }
  }
`;

export const MUTATION_DELETE_VARIATION = gql`
  mutation ($variationId: Int!) {
    surveys {
      questions {
        variations {
          delete(variationId: $variationId)
        }
      }
    }
  }
`;
