import { useMutation, useQuery } from "@apollo/client";
import { Box, Checkbox } from "@mui/material";
import dayjs from "dayjs";
import { some } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MUTATION_ACTIVATION_CREATE_SURVEY,
  MUTATION_ACTIVATION_EDIT_SURVEY,
  MUTATION_CREATE_SURVEY,
  MUTATION_EDIT_SURVEY
} from "../../../api/survey/mutation";
import { SURVEY_QUERY_ACTIVATION, SURVEY_QUERY_DETAILS } from "../../../api/survey/queries";
import { QuestionType } from "../../../types";
import { convertEnumToOptionList } from "../../../utils/common";
import PageHeader from "../../PageHeader";
import { Form } from "../../Profile/styles";
import { AutocompleteContainer } from "../../Responses/styles";
import Button from "../../ui-kit/Button";
import DatePickerComponent from "../../ui-kit/DatePicker";
import SelectComponent from "../../ui-kit/Select";
import TextBox from "../../ui-kit/Textbox";
import AddQuestionsModal from "../Questions";
import AddAnswerModal from "../SampleAnswers";

import {
  BoxQuestion,
  ContainerSurvey,
  FormCheckbox,
  FormContainer,
  FormLeft,
  FormLeftRow,
  Label,
  RowContainer
} from "../style";
import { SELECT_ACTIVATION_EVENT, SELECT_DAYS_IN_WEEK } from "../types";
import useSurveyFrom from "../useSuerveyForm";
import AddVariationModal from "../Variation";
import SurveyDetilsQuestion from "./details";

interface CustomizedState {
  id: string;
  description: string;
  dateCreated: string;
  isEditing: boolean;
}

interface QuestionDetils {
  surveys: {
    details: {
      questions: {
        id: number;
        questionOrder: number;
        questionType: QuestionType;
      }[];
    };
  };
}

export default function CallculatApiActivation() {
  const location = useLocation();
  const state = location?.state as CustomizedState;
  const id = state?.id;

  const [refetchId, setRefetchId] = useState<string>(id);

  const { data } = useQuery(SURVEY_QUERY_ACTIVATION, {
    skip: !refetchId,
    fetchPolicy: "network-only",
    variables: {
      surveyId: refetchId
    }
  });
  const {
    data: surveyDetails,
    refetch
  } = useQuery(SURVEY_QUERY_DETAILS, {
    skip: !refetchId,
    fetchPolicy: "network-only",
    variables: {
      surveyId: refetchId
    }
  });

  return (
    <CreateSurveyPage
      id={id}
      data={data}
      surveyDetails={surveyDetails}
      refetch={refetch}
      setRefetchId={setRefetchId}
    />
  );
}

function CreateSurveyPage(props: {
  id: string;
  data: any;
  surveyDetails: QuestionDetils;
  refetch: any;
  setRefetchId: any;
}) {
  const { data, surveyDetails, refetch, setRefetchId } = props;
  const [activation, setActivation] = useState<boolean>(false);
  const [fromHourForm, setfromHourForm] = useState<boolean>(true);
  const [fromHourTo, setFromHourTo] = useState<boolean>(true);
  const [eventsForm, setEventsForm] = useState<boolean>(true);
  const [daysForm, setDaysForm] = useState<boolean>(true);
  const [calendarForm, setCalendarForm] = useState<boolean>(true);
  const [calendarTo, setCalendarTo] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAddVariationModal, setOpenAddVariationModal] =
    useState<boolean>(false);
  const [openAddAnswerModal, setOpenAddAnswerModal] = useState<boolean>(false);

  const location = useLocation();
  const state = location?.state as CustomizedState; // Type Casting,  router
  const navigate = useNavigate();
  const activactionData = data?.surveys?.details?.activation;
  const questionDetails = surveyDetails?.surveys?.details?.questions;

  const [questionToAddAVariationTo, setQuestionToAddVariationTo] =
    useState<number>(-1);
  const [questionToAddAnAnswerTo, setQuestionToAddAnAnswerTo] =
    useState<number>(-1);

  //get the last index
  const lastQuestionOrder =
    surveyDetails?.surveys?.details?.questions?.sort((a, b) =>
      a.id > b.id ? -1 : 1
    )[0]?.questionOrder || 0;

  const {
    form,
    onUpdateFormField,
    syncForm,
    onUpdateDaysBy,
    onCanSubmitMultiple,
    canSubmitMultiple,
    activateOnDays,
    activateOnEvents,
    onUpdateActivationBy,
    fieldErrors
  } = useSurveyFrom();

  const activationOptions = useMemo(
    () => convertEnumToOptionList(SELECT_ACTIVATION_EVENT),
    []
  );
  const daysByWeek = useMemo(
    () => convertEnumToOptionList(SELECT_DAYS_IN_WEEK),
    []
  );

  const [createForm, { loading: isCreateFrom }] = useMutation(
    MUTATION_CREATE_SURVEY
  );
  const [editForm, { loading: isEditingForm }] =
    useMutation(MUTATION_EDIT_SURVEY);

  const [activateForm, { loading: isEditingActivation }] = useMutation(
    MUTATION_ACTIVATION_CREATE_SURVEY
  );

  const [activateFormEdit] = useMutation(
    MUTATION_ACTIVATION_EDIT_SURVEY
  );


  //Check if it Is on Edit route
  const isEditing = state?.isEditing;

  useEffect(() => {
    if (state?.id === undefined && state?.isEditing === undefined) {
      return;
    }
    const { id, description, dateCreated } = state;

    syncForm({ id, description, dateCreated, canSubmitMultiple });
  }, [state, canSubmitMultiple, syncForm]);

  //Check for enable/disabled button
  const handleFromHourTo = () =>
    fromHourTo && isEditing
      ? dayjs(`2023-01-01 ${activactionData?.activateToHour}:00`) || ""
      : dayjs(`2023-01-01 ${form.activateToHour}:00`) || "";
  const handleFromHourFrom = () =>
    fromHourForm && isEditing
      ? dayjs(`2023-01-01 ${activactionData?.activateFromHour}:00`) || ""
      : dayjs(`2023-01-01 ${form.activateFromHour}:00`) || "";
  const handleOnEvents = () =>
    eventsForm && isEditing
      ? activactionData?.activateOnEvents || []
      : activateOnEvents || [];
  const handleDaysInWeek = () =>
    daysForm && isEditing
      ? activactionData?.activateOnDays || []
      : activateOnDays || [];
  const handleCalendarFrom = () =>
    calendarForm && isEditing
      ? new Date(activactionData?.activateFrom) || ""
      : new Date(form.activeFrom) || "";
  const handleCalendarTo = () =>
    calendarTo && isEditing
      ? new Date(activactionData?.activateTo) || ""
      : new Date(form.activeTo) || "";

  const isSaveDisabled =
    isEditingForm ||
    isCreateFrom ||
    isEditingActivation ||
    some(fieldErrors) ||
    form.surveyId === undefined ||
    form.description === undefined;

  const { description, surveyId } = form;

  const variablesProps = () => ({
    activation: {
      activateFromHour: 0,
      activateToHour: 23,
      activateOnDays: handleDaysInWeek(),
      activeTo: handleCalendarTo().toISOString(),
      surveyId: surveyId,
      activeFrom: handleCalendarFrom().toISOString(),
      activateOnEvents: handleOnEvents()
    }
  });
  const onSubmit = async () => {
    const id = surveyId;
    if (isEditing) {
      await onEditActionvation();
    } else {
      if (!activation) {
        setRefetchId(surveyId);

        await createForm({
          variables: {
            survey: { canSubmitMultiple, description, id }
          }
        });
        refetch();
      }
      setActivation(true);
      if (activation) {
        const result = await activateForm({
          variables: variablesProps()
        });
        if (!result.errors) {
          setActivation(false);
          navigate("/survey");
        }
      }
    }
  };
  const onEditActionvation = async () => {
    const id = surveyId;
    await editForm({
      variables: {
        survey: { canSubmitMultiple, description, id }
      }
    });
    const result = await activateFormEdit({
      variables: variablesProps()
    });

    if (!result.errors) {
      navigate("/survey", {
        state: {
          surveyId: surveyId
        }
      });
    }
  };
  const handleOnOpenModal = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    refetch();
    setOpenModal(false);
  };

  const handleAddVariationModalClose = () => {
    refetch();
    setOpenAddVariationModal(false);
  };

  const handleAddVariationModalOpen = (questionId: number) => {
    setQuestionToAddVariationTo(questionId);
    setOpenAddVariationModal(true);
  };

  const handleAddAnswerModalClose = () => {
    refetch();
    setOpenAddAnswerModal(false);
  };

  const handleAddAnswerModalOpen = (questionId: number) => {
    setQuestionToAddAnAnswerTo(questionId);
    setOpenAddAnswerModal(true);
  };

  return (
    <ContainerSurvey>
      <PageHeader>Survey Details</PageHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <AddQuestionsModal
          open={openModal}
          handleClose={handleClose}
          id={form.surveyId}
          lastQuestionOrder={lastQuestionOrder}
        ></AddQuestionsModal>
        <AddVariationModal
          open={openAddVariationModal}
          handleClose={handleAddVariationModalClose}
          questionId={questionToAddAVariationTo} />
        <AddAnswerModal
          open={openAddAnswerModal}
          handleClose={handleAddAnswerModalClose}
          questionId={questionToAddAnAnswerTo} />
        <FormContainer>
          <RowContainer>
            <FormLeft>
              <Label>Name (ID):</Label>
              <TextBox
                width="100%"
                placeholder="ID"
                isDisabled={activation || isEditing}
                value={form.surveyId || ""}
                onChange={(value) => onUpdateFormField("surveyId", value)}
                errorMessage={fieldErrors.surveyId}
                materialProps={{ size: "small" }}
              />
            </FormLeft>
          </RowContainer>
          <RowContainer>
            <FormLeft>
              <Label>Description:</Label>
              <TextBox
                width="100%"
                isDisabled={activation}
                placeholder="Description"
                value={form.description || ""}
                onChange={(value) => onUpdateFormField("description", value)}
                errorMessage={fieldErrors.description}
                materialProps={{ size: "small" }}
              />
            </FormLeft>
          </RowContainer>
          <RowContainer>
            <FormCheckbox>
              <Label>Can be submited multiple times:</Label>
              <Checkbox
                checked={canSubmitMultiple}
                disabled={activation}
                onChange={(value) => onCanSubmitMultiple(value.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </FormCheckbox>
            <br />
          </RowContainer>
          {(activation || isEditing) && (
            <Box>
              <h4>Activation setup:</h4>
              <RowContainer>
                <AutocompleteContainer>
                  <FormLeftRow>
                    <DatePickerComponent
                      placeholder="From date"
                      value={handleCalendarFrom()}
                      handleDateChange={(value) => {
                        setCalendarForm(false);
                        if (value) {
                          onUpdateFormField("activeFrom", value.toString());
                        }
                      }}
                    />
                    <DatePickerComponent
                      placeholder="To date"
                      value={handleCalendarTo()}
                      handleDateChange={(value) => {
                        setCalendarTo(false);
                        if (value) {
                          onUpdateFormField("activeTo", value.toString());
                        }
                      }}
                      shouldDisableDate={(dateParam) => {
                        //your_condition here
                        //return true to disabled and false to enable
                        const fromFormatted = handleCalendarFrom()
                          .toISOString()
                          .split("T")[0];
                        const currentDate = dateParam
                          .toISOString()
                          .split("T")[0];
                        return fromFormatted >= currentDate;
                      }}
                    />
                  </FormLeftRow>
                </AutocompleteContainer>
              </RowContainer>
              <RowContainer>
                <SelectComponent
                  id="activation-event-select"
                  materialProps={{ size: "small" }}
                  placeholder="Activation Event"
                  options={activationOptions}
                  multiple={true}
                  value={handleOnEvents()}
                  onChange={(value) => {
                    setEventsForm(false);
                    onUpdateActivationBy(value);
                  }}
                ></SelectComponent>
              </RowContainer>
              <RowContainer>
                <SelectComponent
                  id="daysByWeek"
                  multiple={true}
                  materialProps={{ size: "small" }}
                  placeholder="Days in week"
                  options={daysByWeek}
                  value={handleDaysInWeek()}
                  onChange={(value) => {
                    setDaysForm(false);
                    onUpdateDaysBy(value);
                  }}
                ></SelectComponent>
              </RowContainer>
              <h4>Questions in survey:</h4>
              {questionDetails?.length > 0 ? (
                <SurveyDetilsQuestion
                  data={questionDetails}
                  onAddVariationClicked={handleAddVariationModalOpen}
                  onAddAnswerClicked={handleAddAnswerModalOpen}
                  onVariationDeleted={handleAddVariationModalClose}
                  onAnswerDeleted={handleAddAnswerModalClose}
                />
              ) : (
                ""
              )}
              <BoxQuestion>
                <Button width="30%" height="35px" onClick={handleOnOpenModal}>
                  Add Question
                </Button>
              </BoxQuestion>
            </Box>
          )}
          <RowContainer>
            <Box>
              <Button
                isDisabled={isSaveDisabled}
                width="30%"
                height="35px"
                type="submit"
              >
                {isEditingForm ? "Saving" : "Save Changes"}
              </Button>
            </Box>
          </RowContainer>
        </FormContainer>
      </Form>
    </ContainerSurvey>
  );
}
