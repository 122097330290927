import React from "react";

import { Footer, LogoContainer } from "./styles";

const FooterComponent: React.FC = () => {
  return (
    <Footer>
      <LogoContainer>
        <img src="/widnet-black-logo.png" width="100%" />
      </LogoContainer>
    </Footer>
  );
};

export default FooterComponent;
