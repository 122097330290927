import React from "react";
import {
  Bar,
  BarChart, CartesianGrid,
  Cell, Legend,
  ResponsiveContainer, Tooltip, XAxis, YAxis
} from "recharts";

import { ChartContainer } from "./styles";

type Props = {
  data: BarChartData[] | undefined;
};

export interface BarChartData {
  group: string;
  nps: number;
}

const colors = [
  "#D9ED92",
  "#34A0A4",
  "#B5E48C",
  "#168AAD",
  "#99D98C",
  "#1A759F",
  "#76C893",
  "#1E6091",
  "#52B69A",
  "#184E77"
];

const BarChartComponent: React.FC<Props> = ({ data }) => {
  if (data === undefined) {
    return null;
  }

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={730} height={250} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="group" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="nps" fill="#8884d8" >
            {
              data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
};

export default BarChartComponent;