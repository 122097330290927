import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const Container = styled.div`
  padding: 8px;
`;

export const TopSection = styled(Box)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colours.grey.secondary};
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  padding: 10px;
`;

export const FiltersContainer = styled(Box)`
  display: grid;
  gap: 10px;
  grid-template-columns: 24% 24% 24% 24%;
  margin-bottom: 10px;
`;

export const AutocompleteContainer = styled(Box)`
  background-color: white;
`;

export const SubmitButtonSection = styled(Box)`
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-bottom: 40px;
`;

export const SelectComponent = styled(Box)`
  background-color: white;
`;
