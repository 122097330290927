import ProtectedRoute from "../../components/ProtectedRoute";
import CreateUser from "../../components/CreateUser";

function CreateUserPage() {
  return (
    <ProtectedRoute>
      <CreateUser />
    </ProtectedRoute>
  );
}

export default CreateUserPage;
