import ProtectedRoute from "../../components/ProtectedRoute";
import CreateSurveyPageComponent from "../../components/Survey/CreateSurvey";

function CreateSurveyPage() {
  return (
    <ProtectedRoute>
      <CreateSurveyPageComponent />
    </ProtectedRoute>
  );
}

 export default CreateSurveyPage;
