import Home from "../../components/Home";
import ProtectedRouteComponent from "../../components/ProtectedRoute";

const HomePage = () => (
  <ProtectedRouteComponent>
    <Home />
  </ProtectedRouteComponent>
);

export default HomePage;
