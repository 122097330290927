import Profile from "../../components/Profile";
import ProtectedRouteComponent from "../../components/ProtectedRoute";

const ProfilePage = () => (
  <ProtectedRouteComponent>
    <Profile />
  </ProtectedRouteComponent>
);

export default ProfilePage;
 