import {
  addMinutes,
  getUnixTime,
  differenceInMilliseconds,
  fromUnixTime,
} from "date-fns";
import {
  BASE_API_URL,
  STORAGE_KEYS,
  TOKEN_EXPIRATION_AFTER_N_MINUTES,
} from "../constants";
import storage from "./storage";

export const getTokenRefreshDate = (tokenCreatedAt: Date): Date =>
  addMinutes(tokenCreatedAt, TOKEN_EXPIRATION_AFTER_N_MINUTES - 2); // We refresh it 2 minutes before expiration, we can play with this number

export const storeAuthMetadata = (token: string, createdAt: Date) => {
  const { AUTH_TOKEN, TOKEN_CREATED_AT } = STORAGE_KEYS;
  storage.set(AUTH_TOKEN, token);
  storage.set(TOKEN_CREATED_AT, getUnixTime(createdAt));
};

export const removeAuthMetadata = () => {
  const { AUTH_TOKEN, TOKEN_CREATED_AT } = STORAGE_KEYS;
  storage.remove(AUTH_TOKEN);
  storage.remove(TOKEN_CREATED_AT);
};

export const getAuthorizationHeaders = () => ({
  authorization: `Bearer ${storage.get(STORAGE_KEYS.AUTH_TOKEN)}`,
});

export const getRefreshTokenCountdownMilliseconds = () => {
  const { TOKEN_CREATED_AT } = STORAGE_KEYS;
  const tokenCreatedAt = fromUnixTime(storage.get(TOKEN_CREATED_AT));
  const tokenRefreshDate = getTokenRefreshDate(tokenCreatedAt);
  const currentDate = new Date();

  const refreshCountdown = differenceInMilliseconds(
    tokenRefreshDate,
    currentDate
  );

  return refreshCountdown;
};

export const fetchRefreshToken = async (): Promise<string | undefined> => {
  try {
    const response = await fetch(`${BASE_API_URL}/api/refresh`, {
      headers: getAuthorizationHeaders(),
    });

    if (!response.ok) {
      return;
    }

    const responseBody = await response.text();

    return responseBody;
  } catch (err) {
    console.log("Error refreshing token", err);
    return;
  }
};
