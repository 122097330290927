import { useMutation } from "@apollo/client";
import { Box, Modal } from "@mui/material";
import React, { useMemo, useState } from "react";
import { MUTATION_CREATE_VARIATION } from "../../../api/survey/variations/mutation";
import { convertEnumToOptionList } from "../../../utils/common";
import PageHeader from "../../PageHeader";
import Button from "../../ui-kit/Button";
import SelectComponent from "../../ui-kit/Select";
import TextBox from "../../ui-kit/Textbox";
import { Form, FormContainer, ModalForm, ModalQuestion, RowContainer } from "../style";
import { LANGUAGES, LanguagesOption } from "../types";

function AddVariationModal(props: {
  open: boolean;
  handleClose: any;
  questionId: number | undefined;
}) {
  const { open, handleClose, questionId } = props;
  const [questionLanguage, setQuestionLanguage] =
    useState<LanguagesOption>("en");
  const [questionText, setQuestionText] = useState<string>("");
  const languageOptions = useMemo(
    () => convertEnumToOptionList(LANGUAGES),
    []
  );

  const [createVariation, { loading: isVariationEditing }] = useMutation(
    MUTATION_CREATE_VARIATION
  );
  const onSubmitVariation = async (e) => {
    e.preventDefault();

    await createVariation({
      variables: {
        variation: {
          questionId,
          questionLanguage,
          questionText,
        },
      },
    });

    handleClose();
  };
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <ModalForm>
        <ModalQuestion>
          <PageHeader>Add Variation</PageHeader>
          <Form>
            <FormContainer>
              <RowContainer>
                <SelectComponent
                  id="language-select"
                  materialProps={{ size: "small" }}
                  placeholder="Language"
                  options={languageOptions}
                  value={questionLanguage}
                  onChange={setQuestionLanguage}
                ></SelectComponent>
              </RowContainer>
              <RowContainer>
                <TextBox
                  width="100%"
                  placeholder="Question Text"
                  value={questionText}
                  onChange={setQuestionText}
                  materialProps={{ size: "small" }}
                />
              </RowContainer>
            </FormContainer>
            <RowContainer>
              <Box>
                <Button
                  isDisabled={isVariationEditing}
                  width="30%"
                  height="35px"
                  onClick={onSubmitVariation}
                >
                  {isVariationEditing ? "Saving" : "Save Changes"}
                </Button>
              </Box>
            </RowContainer>
          </Form>
        </ModalQuestion>
      </ModalForm>
    </Modal>
  );
}

export default AddVariationModal;
