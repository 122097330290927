import { useMutation } from "@apollo/client";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React from "react";
import { MUTATION_DELETE_SAMPLE_ANSWER } from "../../../api/survey/sampleAnswers/mutation";
import { MUTATION_DELETE_VARIATION } from "../../../api/survey/variations/mutation";
import { QuestionType } from "../../../types";
import Button from "../../ui-kit/Button";
import { DetailsContainer, TableModal } from "../style";

interface VariationDetails {
  id: number;
  language: string;
  text: string;
}

interface SampleAnswer {
  id: number;
  language: string;
  answerText: string;
  assignedValue: number;
}

interface QuestionDetils {
  id: number;
  questionOrder: number;
  questionType: QuestionType;
  variations?: VariationDetails[];
  answers?: SampleAnswer[];
}

function SurveyDetilsQuestion(props: {
  data: QuestionDetils[];
  onAddVariationClicked: (number) => void;
  onAddAnswerClicked: (number) => void;
  onVariationDeleted: () => void;
  onAnswerDeleted: () => void;
}) {
  const {
    data,
    onAddVariationClicked,
    onVariationDeleted,
    onAddAnswerClicked,
    onAnswerDeleted,
  } = props;

  const [deleteVariation, { loading: isVariationDeleting }] = useMutation(
    MUTATION_DELETE_VARIATION
  );

  const onDeleteVariation = (variationId: number) => {
    deleteVariation({
      variables: {
        variationId,
      },
    }).then(() => onVariationDeleted());
  };

  const [deleteAnswer] = useMutation(MUTATION_DELETE_SAMPLE_ANSWER);
  const onDeleteAnswerClicked = async (answerId: number) => {
    await deleteAnswer({
      variables: { answerId },
    });
    onAnswerDeleted();
  };
  return (
    <DetailsContainer>
      {data
        .sort((x, y) => x.questionOrder - y.questionOrder)
        .map((question) => {
          return (
            <Accordion key={question.id}>
              <AccordionSummary>
                <Typography>
                  <b>Order:</b> {question.questionOrder} | <b>Id: </b>
                  {question.id} | {question.questionType}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Question variations:
                  <TableModal size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Language</TableCell>
                        <TableCell>Text</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {question?.variations?.map((variation) => {
                        return (
                          <React.Fragment key={variation.id}>
                            <TableRow>
                              <TableCell>{variation.language}</TableCell>
                              <TableCell>{variation.text}</TableCell>
                              <TableCell>
                                <Button
                                  isDisabled={isVariationDeleting}
                                  width="30%"
                                  onClick={() =>
                                    onDeleteVariation(variation.id)
                                  }
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                      <TableRow>
                        <TableCell colSpan={3} align="right">
                          <Button
                            onClick={() => onAddVariationClicked(question.id)}
                          >
                            Add Variation
                          </Button>
                          <br />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </TableModal>
                  {question.questionType === "SINGLE_CHOICE"
                    ? "Sample answers:"
                    : ""}
                  {question.questionType === "SINGLE_CHOICE" ? (
                    <TableModal size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Language</TableCell>
                          <TableCell>Answer Text</TableCell>
                          <TableCell>Assigned Value</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {question?.answers
                          ?.sort((x, y) => (x.language > y.language ? 1 : -1))
                          ?.map((ans) => {
                            return (
                              <React.Fragment key={ans.id}>
                                <TableRow>
                                  <TableCell>{ans.language}</TableCell>
                                  <TableCell>{ans.answerText}</TableCell>
                                  <TableCell>{ans.assignedValue}</TableCell>
                                  <TableCell>
                                    <Button
                                      onClick={() =>
                                        onDeleteAnswerClicked(ans.id)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            );
                          })}
                        {question.questionType === "SINGLE_CHOICE" ? (
                          <TableRow>
                            <TableCell colSpan={4} align="right">
                              <Button
                                onClick={() => onAddAnswerClicked(question.id)}
                              >
                                Add Sample Answer
                              </Button>
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </TableModal>
                  ) : (
                    ""
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </DetailsContainer>
  );
}

SurveyDetilsQuestion.propTypes = {};

export default SurveyDetilsQuestion;
