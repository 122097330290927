import styled from "@emotion/styled";
import { Box } from "@mui/material";


export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const WidthContainer = styled.div`
  width: 24%;
  margin: 1px;
`;

export const ChartContainer = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.modals};
  width: 97%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
`;

export const FiltersContainer = styled(Box)`
  display: grid;
  gap: 25px 10px;
  grid-template-columns: repeat(4, 24%);
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
`;

export const TopSection = styled(Box)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colours.grey.secondary};
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const SubmitButtonSection = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoDataBanner = styled(Box)`
  color: ${({ theme }) => theme.colours.red.primary};
  font-size: 16px;
  width: 100%;
  text-align: center;
`;

export const TableLoaderContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
