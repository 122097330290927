import ProtectedRoute from "../../components/ProtectedRoute";
import SurveyComponent from "../../components/Survey";
import ChatComponent from "../../components/Chat";

function ChatPage() {
  return (
    <ProtectedRoute>
      <ChatComponent />
    </ProtectedRoute>
  );
}

 export default ChatPage;
