import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";

import { ITableData } from "../../types";

import {
  ColorEmployeeCell,
  FreezedTableCell,
  HighScoreRow,
  TableContainers,
  TableParagraphContainer,
  TableWidth,
  TableTotals,
} from "./styles";

type Props = {
  tableData: ITableData;
  tableColumns: string[] | undefined;
};

const TableComponent: React.FC<Props> = ({ tableData, tableColumns }) => {
  const { overallGrade, chatVolume, totalGrade, totalVolume, nps, totalNps } = tableData;

  return (
    <>
      <TableParagraphContainer>
        <TableContainers>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: "transparent" }}>/</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Chat Volume (# of responses)</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>MAIN SCORE</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>Overall Employee grade</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            <TableRow>
              <TableCell>e-NPS</TableCell>
            </TableRow>
          </TableBody>
        </TableContainers>
      </TableParagraphContainer>

      <TableContainers>
        <TableWidth aria-label="simple table">
          {/* <TableTotals> */}
          <TableHead>
            <TableRow>
              {tableColumns?.map((row, index) => (
                <TableCell key={index} align="right">
                  {row}
                </TableCell>
              ))}
              <TableTotals>
                {" "}
                <FreezedTableCell align="right"> Total</FreezedTableCell>
              </TableTotals>
            </TableRow>
          </TableHead>
          {/* </TableTotals> */}
          <TableBody>
            <TableRow>
              {chatVolume.map((row, index) => (
                <TableCell key={index} align="right">
                  {row}
                </TableCell>
              ))}
              <TableTotals>
                <FreezedTableCell align="right">
                  {" "}
                  {totalVolume}
                </FreezedTableCell>
              </TableTotals>
            </TableRow>
            <TableRow>
              <HighScoreRow align="right"></HighScoreRow>
            </TableRow>
            <TableRow>
              {overallGrade.map((row, index) => (
                <ColorEmployeeCell key={index} align="right">
                  {row.toFixed(2)}
                </ColorEmployeeCell>
              ))}
              <TableTotals>
                {" "}
                <FreezedTableCell align="right">
                  {" "}
                  {totalGrade.toFixed(2)}
                </FreezedTableCell>
              </TableTotals>
            </TableRow>
            <TableRow>
              {nps.map((row, index) => (
                <TableCell key={index} align="right">
                  {row.toFixed(2)}
                </TableCell>
              ))}
              <TableTotals>
                <FreezedTableCell align="right">
                  {" "}
                  {totalNps.toFixed(2)}
                </FreezedTableCell>
              </TableTotals>
            </TableRow>
          </TableBody>
        </TableWidth>
      </TableContainers>
    </>
  );
};
export default TableComponent;
