import { gql } from "@apollo/client";

export const MUTATION_EDIT_USER = gql`
  mutation ($editUserInput: EditUserInput!, $id: Int!) {
    editUser(editUserInput: $editUserInput, id: $id) {
      id
    }
  }
`;

export const MUTATION_REGISTER_USER = gql`
  mutation ($registerUserInput: RegisterUserInput!) {
    registerUser(registerUserInput: $registerUserInput) {
      id
    }
  }
`;
