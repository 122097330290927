import React, { PropsWithChildren, useEffect } from "react";
import { useAuthContext } from "../../context/auth";
import PageSpinner from "../PageSpinner";
import { useNavigate } from "react-router-dom";
import { useMeQuery } from "../../hooks/useMeQuery";

import storage from "../../utils/storage";
import { STORAGE_KEYS } from "../../constants";
import { fromUnixTime } from "date-fns";

const ProtectedRouteComponent: React.FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { user, setUser, refreshTokenTimeoutId, handleAuthTokenFlow } =
    useAuthContext();

  const { error, data, loading } = useMeQuery();

  const clearUserAndRedirect = () => {
    setUser(undefined);
    navigate(`/login`, { replace: true });
  };

  useEffect(() => {
    if (!error) {
      return;
    }

    clearUserAndRedirect();
  }, [error]);

  useEffect(() => {
    if (!data && !loading) {
      clearUserAndRedirect();
      return;
    }

    if (loading) {
      return;
    }

    const user = data?.me;
    setUser(user);
    const { AUTH_TOKEN, TOKEN_CREATED_AT } = STORAGE_KEYS;

    if (refreshTokenTimeoutId === undefined) {
      handleAuthTokenFlow(
        storage.get(AUTH_TOKEN),
        fromUnixTime(storage.get(TOKEN_CREATED_AT))
      );
    }
  }, [data, loading]);

  if (loading || !user) {
    return <PageSpinner />;
  }

  return <div>{children}</div>;
};

export default ProtectedRouteComponent;
