import styled from "@emotion/styled";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";

import TableContainer from "@mui/material/TableContainer/TableContainer";

export const TableParagraphContainer = styled.div`
  min-width: 21%;
`;

export const FreezedTableCell = styled(TableCell)`
  position: sticky;
  right: 0;
  background: #e8f3fd;
  width: 100px;
`;
export const TableWidth = styled(Table)`
  min-width: calc(80% + 100px);
`;
export const TableTotals = styled.div`
  width:100px;
  display: contents;
`;

export const TableContainers = styled(TableContainer)`
  max-width: 100%;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${({ theme }) => theme.colours.green.secondary};
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colours.green.secondary};
    border-radius: 10px;
  }
`;

export const HighScoreRow = styled(TableCell)`
  height: 21px;
`;

export const ColorEmployeeCell = styled(TableCell)`
  background: #59eadc;
`;

export const StyledParagraph = styled.div`
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
  border-collapse: collapse;
  border-spacing: 0;
`;
