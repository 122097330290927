import React, { PropsWithChildren } from "react";
import { InheritedStylesLink, StyledLink } from "./styles";

type Props = PropsWithChildren<{
  href: string | undefined;
  shouldInheritStyles?: boolean;
}>;

const LinkComponent: React.FC<Props> = ({
  href,
  children,
  shouldInheritStyles,
}) => {
  const Component = shouldInheritStyles ? InheritedStylesLink : StyledLink;

  return <Component href={href}>{children}</Component>;
};
export default LinkComponent;
