import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { FlexContainer } from "../../styles/common";

export const SX_STYLE = { marginTop: "18px" };

export const Container = styled.div`
  padding: 8px;
`;

export const Form = styled.form`
  padding: 10px;
  width: 60%;
  min-width: 500px;
  max-width: 700px;
`;

export const FormContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

export const FormLeft = styled(Box)`
  margin-right: 32px;
  flex: 3;
`;

export const FormRight = styled(Box)`
  flex: 2;
`;

export const ButtonsContainer = styled(Box)`
  padding: 32px 0px;
  display: flex;
  justify-content: center;
`;

export const ControlContainer = styled(Box)(SX_STYLE);
