import styled from "@emotion/styled";

export const Container = styled.div``;
export const Content = styled.div`
  width: calc(100% - 250px);
  margin-left: 250px;
  margin-top: 50px;
  padding: 7px;
  box-sizing: border-box;
`;
