import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import { FlexContainer } from "../../styles/common";
import TextBox from "../ui-kit/Textbox";

export const Form = styled.form`
  padding: 10px;
  width: 95%;
`;

export const FormContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

export const FormLeft = styled(Box)`
  margin-right: 32px;
  flex: 2;
`;

export const FormRight = styled(Box)`
  flex: 2;
`;

export const Container = styled.div`
  padding: 8px;
`;

export const StyledTextBox = styled(TextBox)`
  margin-bottom: 5px;
  margin-top: 10px;
`;

export const ButtonsContainer = styled(Box)`
  padding: 32px 20%;
  justify-content: center;
  width: 60%;
  display: flex;
`;

export const StyledDiv = styled.div`
  margin-top: 10px;
`;
