import ProtectedRoute from "../../components/ProtectedRoute";
import Users from "../../components/Users";

function UsersPage() {
  return (
    <ProtectedRoute>
      <Users />
    </ProtectedRoute>
  );
}

export default UsersPage;
