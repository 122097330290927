import React, { PropsWithChildren } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

type Props = PropsWithChildren<{
  placeholder: string;
  value?: Date;
  handleDateChange: (value: Date) => void;
  shouldDisableDate?:(value:Date)=>boolean
}>;

const DatePickerComponent: React.FC<Props> = ({
  placeholder,
  value,
  shouldDisableDate,
  handleDateChange,
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      label={placeholder}
      value={value}
      shouldDisableDate={(value) =>shouldDisableDate?.(value as Date) || false}
      onChange={(value) => handleDateChange(value as Date)}
      renderInput={(params) => <TextField {...params} fullWidth size="small" />}
    />
  </LocalizationProvider>
);

export default DatePickerComponent;
