import React, { PropsWithChildren, ReactElement } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionProps,
  AccordionDetails,
  AccordionSummary,
  SxProps,
  Theme,
} from "@mui/material";

type Props = PropsWithChildren<{
  id: string;
  sx?: SxProps<Theme> | undefined;
  accordionDetailsSx?: SxProps<Theme> | undefined;
  isDisabled?: boolean;
  materialProps?: AccordionProps;
  summary: ReactElement | string;
}>;

const AccordionComponent: React.FC<Props> = ({
  id,
  children,
  sx,
  summary,
  accordionDetailsSx,
  materialProps,
}) => (
  <Accordion disableGutters={true} sx={sx} {...materialProps}>
    <AccordionSummary id={id} expandIcon={<ExpandMoreIcon />}>
      {summary}
    </AccordionSummary>
    <AccordionDetails sx={accordionDetailsSx}>{children}</AccordionDetails>
  </Accordion>
);

export default AccordionComponent;
