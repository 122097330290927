import ProtectedRoute from "../../components/ProtectedRoute";
import TeamProfiles from "../../components/TeamProfile";

function TeamProfilePage() {
  return (
    <ProtectedRoute>
      <TeamProfiles />
    </ProtectedRoute>
  );
}

 export default TeamProfilePage;
