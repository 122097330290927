import { useMutation } from "@apollo/client";
import Typography from "@mui/material/Typography";
import some from "lodash/some";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { MUTATION_EDIT_USER } from "../../api/users/mutations";

import { useAuthContext } from "../../context/auth";
import { USER_ROLES } from "../../enums";
import { convertEnumToOptionList } from "../../utils/common";

import Accordion from "../ui-kit/Accordion";
import Button from "../ui-kit/Button";
import Select from "../ui-kit/Select";
import TextBox from "../ui-kit/Textbox";

import PageHeader from "../PageHeader";

import {
  ButtonsContainer,
  Container,
  ControlContainer,
  Form,
  FormContainer,
  FormLeft,
  FormRight,
  SX_STYLE,
} from "./styles";

import { STORAGE_KEYS } from "../../constants";
import storage from "../../utils/storage";
import useUserProfileForm from "./use-form";
import { LANGUAGES } from "../Survey/types";

const ProfileComponent: React.FC = () => {
  const { user } = useAuthContext();
  const { t, i18n } = useTranslation();
  const token = storage.get(STORAGE_KEYS.AUTH_TOKEN);
  useEffect(() => {
    i18n.changeLanguage(user?.language);
  }, [user?.language]);

  const { form, onUpdateFormField, syncForm, fieldErrors } =
    useUserProfileForm();
  const [editUser, { loading: isEditingUser }] =
    useMutation(MUTATION_EDIT_USER);

  const languagesOptions = useMemo(
    () => convertEnumToOptionList(LANGUAGES),
    [LANGUAGES]
  );

  const userRolesOptions = useMemo(
    () => convertEnumToOptionList(USER_ROLES),
    [USER_ROLES]
  );

  useEffect(() => {
    if (user === undefined) {
      return;
    }

    syncForm(user);
  }, [user]);

  const onSubmit = async () => {
    console.log(form);

    const { fullName, language, password } = form;

    const result = await editUser({
      variables: {
        id: user?.id,
        editUserInput: { fullName, language, password },
      },
    });

    //TODO: Add some feedback to the screen

    console.log(result);
  };

  const isSaveDisabled = isEditingUser || some(fieldErrors);

  return (
    <Container>
      <PageHeader>User Profile</PageHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <FormContainer>
          <FormLeft>
            <TextBox
              width="100%"
              placeholder="User Name"
              value={form.fullName || ""}
              errorMessage={fieldErrors.fullName}
              onChange={(value) => onUpdateFormField("fullName", value)}
            />
            <Select
              id="select-language"
              options={languagesOptions}
              placeholder="Preferred language"
              onChange={(value) => onUpdateFormField("language", value)}
              sx={SX_STYLE}
              value={form.language || ""}
            />
            <Select
              id="select-user-role"
              options={userRolesOptions}
              placeholder="User Role"
              isDisabled={true}
              sx={SX_STYLE}
              value={user?.role || ""}
            />
          </FormLeft>
          <FormRight>
            <Accordion
              id="change-password-select"
              summary={<Typography>Change password</Typography>}
            >
              <TextBox
                type="password"
                width="100%"
                placeholder="New password"
                value={form.password || ""}
                onChange={(value) => onUpdateFormField("password", value)}
                errorMessage={fieldErrors.password}
                materialProps={{ size: "small" }}
              />
              <ControlContainer>
                <TextBox
                  isDisabled={!Boolean(form.password)}
                  type="password"
                  width="100%"
                  placeholder="Confirm password"
                  value={form.confirmPassword || ""}
                  onChange={(value) =>
                    onUpdateFormField("confirmPassword", value)
                  }
                  errorMessage={fieldErrors.confirmPassword}
                  materialProps={{ size: "small" }}
                />
              </ControlContainer>
            </Accordion>
          </FormRight>
        </FormContainer>

        <ButtonsContainer>
          <Button
            isDisabled={isSaveDisabled}
            width="50%"
            height="35px"
            type="submit"
          >
            {isEditingUser ? "Saving" : "Save Changes"}
          </Button>
        </ButtonsContainer>
      </Form>
      {/*<PageHeader>How to authenticate in the demo chat-client</PageHeader>*/}
      {/*<p>*/}
      {/*  <ul>*/}
      {/*    <li>Open up the chat-url, e.g.: <pre>http://127.0.0.1:88</pre></li>*/}
      {/*    <li>Add a new token parameter, e.g. <pre>http://127.0.0.1:88?token=</pre></li>*/}
      {/*    <li>Copy the authentication token from below and add it after the equals sign, e.g. <pre>http:127.0.0.1:88?token=eyJ...long_token_here...CtBa</pre></li>*/}
      {/*  </ul>*/}
      {/*</p>*/}
      {/*<p style={{wordBreak:"break-all"}}><b>Authentication Token: </b>{token}</p>*/}
    </Container>
  );
};

export default ProfileComponent;
