import styled from "@emotion/styled";

export const PageHeader = styled.h3`
  height: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colours.grey.secondary};

  padding: 0px 18px;
  margin: 0px;
  margin-bottom: 16px;
  display: flex;
  font-weight: 600;
  align-items: center;
  color: ${({ theme }) => theme.colours.grey.primary};
`;
