import { gql } from "@apollo/client";

export const SURVEY_QUERY = gql`
  query {
    surveys {
      allAvailable {
        id
        description
        dateCreated
      }
    }
  }
`;
export const SURVEY_QUERY_DETAILS = gql`
query ($surveyId: String!) {
  surveys {
    details(surveyId: $surveyId) {
  		questions{
        id
        questionOrder
        questionType
        variations{
          id
          language
          text
        }
        answers{
          id
          language
          answerText
          assignedValue
        }
      }
      
    }
  }
}
`;
export const SURVEY_QUERY_ACTIVATION = gql`
  query ($surveyId: String!) {
    surveys {
      details(surveyId: $surveyId) {
        id
        description
        dateCreated
        activation {
          activateOnDays
          activateToHour
          activateFromHour
          activateOnEvents
          activateTo
          activateFrom
        }
      }
    }
  }
`;
