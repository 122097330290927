import styled from "@emotion/styled";
import Box from "@mui/material/Box";

export const Footer = styled(Box)`
  background-color: ${({ theme }) => theme.colours.grey.secondary};
  height: 50px;
  box-shadow: ${({ theme }) => theme.boxShadow.modals};
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled(Box)`
  width: 100px;
`;
