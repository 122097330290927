import React, { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import map from "lodash/map";

import { OptionValue, Option } from "../../../types";
import { intersectionWith } from "lodash";

type Props = {
  id: string;
  width?: string;
  options: Option[];
  isDisabled?: boolean;
  defaultValue?: OptionValue[];
  onChange?: (values: OptionValue[]) => void;
  placeholder: string;
};

const getOptionsByValues = (
  values: OptionValue[],
  allOptions: Option[]
): Option[] =>
  intersectionWith(
    allOptions,
    values,
    (option, value) => option.value === value
  );

const AutocompleteComponent: React.FC<Props> = ({
  id,
  width,
  options,
  onChange,
  placeholder,
  defaultValue,
}) => {
  const handleOnChange = (_, optionsSelected: Option[]) => {
    const values = map(optionsSelected, "value");

    if (onChange) {
      onChange(values);
    }
  };

  const appliedValues = useMemo(
    () => (defaultValue ? getOptionsByValues(defaultValue, options) : []),
    [defaultValue, JSON.stringify(options)]
  );

  return (
    <Autocomplete
      multiple
      id={id}
      isOptionEqualToValue={(option1, option2) =>
        option1.value === option2.value
      }
      onChange={handleOnChange}
      options={options}
      value={[...appliedValues]}
      size="small"
      sx={{ width: width || "100%" }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={placeholder} />
      )}
    />
  );
};

export default AutocompleteComponent;
