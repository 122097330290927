import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
import $RefreshIcon from "@mui/icons-material/Refresh";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
 
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colours.grey.primary};
`;

export const RefreshIcon = styled($RefreshIcon)`
font-size: 100px;
position: relative;
top: -100px;
color: ${({ theme }) => theme.colours.grey.secondary};
${css`
  animation: ${spin} 0.5s ease infinite;
`}};
`;
