import { useMutation, useQuery } from "@apollo/client";
import { Box, Modal } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../PageHeader";
import Button from "../../ui-kit/Button";
import { Form, FormContainer, ModalForm, ModalQuestion, RowContainer } from "../style";
import { TEAM_ASSIGNMENT_FOR_SURVEY } from "../../../api/survey/teamAssignment/query";
import { GET_AVAILABLE_TEAMS } from "../../../api/teamProfile/queries";
import { Option, OptionValue } from "../../../types";
import { MUTATION_ASSIGN_TEAMS_FOR_SURVEY } from "../../../api/survey/teamAssignment/mutation";
import SelectComponent from "../../ui-kit/Select";


const convertTeamNameToFilterOption = (name: string): Option => {
  return { value: name, label: name };
};

function TeamAssignmentModal(props: {
  open: boolean;
  handleClose: any;
  surveyId: string | undefined;
}) {
  const { open, handleClose, surveyId } = props;
  const [selectedTeams, setSelectedTeams] =
    useState<string[]>([]);
  const { data: teamsAssignmentData } = useQuery(
    TEAM_ASSIGNMENT_FOR_SURVEY,
    {
      fetchPolicy: "network-only",
      variables: {
        surveyId: surveyId
      }
    }
  );

  useEffect(() => {
    const teamsAssignedToSurvey: string[] = teamsAssignmentData?.surveys?.teamAssignment?.get || [];
    setSelectedTeams(teamsAssignedToSurvey);
  }, [teamsAssignmentData]);


  const { data: availableTeamsData } = useQuery(GET_AVAILABLE_TEAMS, {
    fetchPolicy: "network-only"
  });

  const availableTeams: Option[] = useMemo(() => {
    return (
      availableTeamsData?.teams?.availableTeams.map(
        convertTeamNameToFilterOption
      ) || []
    );
  }, [availableTeamsData]);

  const [teamAssignmentMutation, { loading: isTeamBeingAssigned }] = useMutation(
    MUTATION_ASSIGN_TEAMS_FOR_SURVEY
  );
  const onSubmitTeamAssigment = async (e) => {
    e.preventDefault();

    await teamAssignmentMutation({
      variables: {
          surveyId,
          teams: selectedTeams
      }
    });

    handleClose();
  };

  const onUpdateSelectedTeams = (values: OptionValue[]) => {
    setSelectedTeams(values as string[]);
  };
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <ModalForm>
        <ModalQuestion>
          <PageHeader>Assign Survey {surveyId} to Teams</PageHeader>
          <Form>
            <FormContainer>
              <RowContainer>
                <SelectComponent
                  id="assigned-team-select"
                  // materialProps={{ size: "small" }}
                  placeholder="Assigned Teams"
                  options={availableTeams}
                  multiple={true}
                  value={selectedTeams}
                  onChange={onUpdateSelectedTeams}
                ></SelectComponent>
              </RowContainer>
            </FormContainer>
            <RowContainer>
              <Box>
                <Button
                  isDisabled={isTeamBeingAssigned}
                  width="30%"
                  height="35px"
                  onClick={onSubmitTeamAssigment}
                >
                  {isTeamBeingAssigned ? "Saving" : "Save Changes"}
                </Button>
              </Box>
            </RowContainer>
          </Form>
        </ModalQuestion>
      </ModalForm>
    </Modal>
  );
}

export default TeamAssignmentModal;
