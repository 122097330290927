import * as React from "react";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Container from "@mui/material/Container";
import { PageHeader } from "../PageHeader/styles";
import TableCell from "@mui/material/TableCell";
import Button from "../ui-kit/Button";
import { StyledTableCell, SurveyBox } from "./style";
import { useNavigate } from "react-router-dom";
import useSurveyFrom from "./useSuerveyForm";
import TeamAssignmentModal from "./TeamAssignment";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function createData(
  id: string,
  description: string,
  dateCreated: string,
  button: HTMLButtonElement
) {
  return { id, description, dateCreated, button };
}

export default function SurveyCompoent() {


  const { availableTeams } = useSurveyFrom();

  const rows = availableTeams?.map((item) =>
    createData(item.id, item.description, item.dateCreated, item.button)
  ).sort((a, b) => (a.id < b.id ? -1 : 1));
  return <CustomPaginationActionsTable rows={rows} />;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function CustomPaginationActionsTable(props: { rows: any }) {
  const { rows } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openTeamAssignmentModal, setOpenTeamAssignmentModal] =
    useState<boolean>(false);
  const [surveyToAssignTeamsTo, setSurveyToAssignTeamsTo] =
    useState<string>("");

  const navigate = useNavigate();

  const handleTeamAssignmentModalClose = () => {
    setOpenTeamAssignmentModal(false);
  };
  const handleTeamAssignmentModalOpen = (surveyId: string) => {
    setSurveyToAssignTeamsTo(surveyId);
    setOpenTeamAssignmentModal(true);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <PageHeader>Survey</PageHeader>
      <SurveyBox>
        <Button
          onClick={() => navigate("/survey/add")}
          width="20%"
          height="35px"
          type="submit"
        >
          Add new survey
        </Button>
      </SurveyBox>
      <TeamAssignmentModal
        open={openTeamAssignmentModal}
        handleClose={handleTeamAssignmentModalClose}
        surveyId={surveyToAssignTeamsTo}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableBody>
            <TableRow>
              <StyledTableCell align="left">ID</StyledTableCell>
              <StyledTableCell align="left">Description</StyledTableCell>
              <StyledTableCell align="right">Date Created</StyledTableCell>
              <StyledTableCell align="center">Edit</StyledTableCell>
              <StyledTableCell align="center">Assignment</StyledTableCell>
            </TableRow>
            {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
            ).map((row) => {
              const dateCreated = new Date(
                Number(row.dateCreated)
              ).toDateString();
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="left">
                    {row.description}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    {dateCreated}
                  </TableCell>
                  <TableCell style={{ width: 120 }} align="center">
                    <Button
                      onClick={() =>
                        navigate(`/survey/edit/${row.id}`, {
                          state: {
                            id: row.id,
                            description: row.description,
                            dateCreated: row.dateCreated,
                            isEditing: true
                          }
                        })
                      }
                      width="50%"
                      height="35px"
                      type="submit"
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell  style={{width:160}} align="center">
                    <Button type="button"
                            onClick={() => handleTeamAssignmentModalOpen(row.id)}>
                      Team Assignment
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page"
                  },
                  native: true
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
}
