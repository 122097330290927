import { gql } from "@apollo/client";

export const MUTATION_CREATE_SAMPLE_ANSER = gql`
  mutation ($answer: CreateSampleAnswer!) {
    surveys {
      questions {
        answers {
          create(answer: $answer) {
            id
          }
        }
      }
    }
  }
`;

export const MUTATION_DELETE_SAMPLE_ANSWER = gql`
  mutation ($answerId: Int!) {
    surveys {
      questions {
        answers {
          delete(answerId: $answerId)
        }
      }
    }
  }
`;
