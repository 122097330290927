import styled from "@emotion/styled";
import Box from "@mui/material/Box";

import { FlexContainer, FlexContainerCentered } from "../../styles/common";
import TextBox from "../ui-kit/Textbox";

export const Container = styled(FlexContainerCentered)`
  height: 100vh;
`;

export const Form = styled.form`
  border-radius: 4px;
  background-color: white;
  box-shadow: ${({ theme }) => theme.boxShadow.modals};
  width: 400px;
  display: flex;
  flex-direction: column;
`;

export const FormTitle = styled.h4`
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colours.green.secondary};
  font-size: 1.1rem;
  height: 46px;
  font-weight: 500;
  box-shadow: ${({ theme }) => theme.boxShadow.modals};
  border-radius: 4px;
`;

export const FormContent = styled(FlexContainer)`
  padding: 32px;
  flex-direction: column;
`;

export const StyledInputBox = styled(TextBox)`
  width: 100%;
  height: 30px;
`;

export const FieldContainer = styled(FlexContainerCentered)`
  height: 68px;
`;

export const ButtonsContainer = styled(FlexContainerCentered)`
  height: 50px;
`;

export const ErrorMessage = styled(Box)`
  color: ${({ theme }) => theme.colours.red.primary};
  padding: 10px;
  text-align: center;
`;
